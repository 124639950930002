import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { DoctorResponse, DoctorSingleResponse, Doctor, DoctorUser } from '../models/Doctor';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DoctoresapiService extends BaseService {

  createDoctor(doctor: DoctorUser) {
    return this.postBaseRequest<DoctorSingleResponse>('api/doctores', doctor)
      .pipe(map(data => data.data));
  }
  updateDoctor(doctor: DoctorUser) {
    return this.putBaseRequest<DoctorSingleResponse>('api/doctores/' + doctor.id, doctor)
      .pipe(map(data => data.data));
  }
  deleteDoctor(id) {
    return this.deleteBaseRequest<DoctorSingleResponse>('api/doctores/' + id)
      .pipe(map(data => data.res));
  }

  getListadoctores() {
    return this.getBaseRequest<DoctorResponse>('api/doctores')
      .pipe(map(data => data.data));
  }
  getDoctor(id: string) {
    return this.getBaseRequest<DoctorSingleResponse>('api/doctores/' + id)
      .pipe(map(data => data.data));
  }

}
