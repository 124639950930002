export interface Login {
    email: string;
    password: string;
}
export interface LoginResponse {
    access_token?: string;
    token_type?: string;
    expires_in?: number;
    user?: string;
    error?: string;
}
export function userHasRole(role, userData) {
    const user = userData;
    // console.log('userHasRole', user);
    if (!user) {
        return false;
    }
    if (!user.roles) {
        return false;
    }

    for (const theRole of user.roles) {
        if (theRole.name === role) {
            return true;
        }
    }
    return false;
}