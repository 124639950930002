import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }
  handle(token) {
    this.set(token);
    // console.log(this.payload(token));
  }
  set(token: string) {
    localStorage.setItem('token', token);
  }
  get() {
    return localStorage.getItem('token');
  }
  remove() {
    const token = this.get();
    if (token) {
      localStorage.removeItem('token');
    }
  }
  private payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }
  private decode(payload: string) {
    return JSON.parse(atob(payload));
  }
  private isValid() {
    const token = this.get();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return payload.iss === environment.baseUrl + '/api/auth/login';
      }
    }
    return false;
  }
  loggedIn() {
    return this.isValid();
  }
}
