import { Component, OnInit, ViewChild } from '@angular/core';
import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-acreditacion',
  templateUrl: './acreditacion.component.html',
  styleUrls: ['./acreditacion.component.scss']
})
export class AcreditacionComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  tituloAcreditacion: string;
  subtituloAcreditacion: string;
  constructor(private cApi: ContenidosapiService, private actRoute: ActivatedRoute) {
    actRoute.params.subscribe(data => {
      this.getContenidos();
    });
  }

  ngOnInit() {
  }
  getContenidos() {
    this.cApi.getContenido('TITULO_ACREDITACION').subscribe(data => {
      if (data) {
        this.tituloAcreditacion = data.value;
      }
    });
    this.cApi.getContenido('SUBTITULO_ACREDITACION').subscribe(data => {
      if (data) {
        this.subtituloAcreditacion = data.value;
      }
    });
  }
}
