import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { EstadoCaso, Caso } from 'src/app/models/Caso';
import { Boca, PosicionBoca } from 'src/app/models/Dientes';
import { userHasRole } from 'src/app/models/User';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { environment } from 'src/environments/environment';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EstadoRefinamiento, Refinamiento } from '../../../models/Refinamiento';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { ArchivorefinamientoapiService } from '../../../services/archivorefinamientoapi.service';
import { BaseFormPlan, PlanFormChanges } from '../../../models/PlanTratamiento';
import { ArchivoRefinamiento } from '../../../models/Archivorefinamiento';

@Component({
  selector: 'app-formrefinamiento',
  templateUrl: './formrefinamiento.component.html',
  styleUrls: ['./formrefinamiento.component.scss']
})
export class FormrefinamientoComponent implements OnInit {
  estadosRefinamiento = EstadoRefinamiento;
  refinamiento: Refinamiento = {
    id: 0,
    motivoEnvio: "",
    motivoOtroExplique: "",
    nroAlineacionSuperior: "", nroAlineacionInferior: "", caso_id: 0, status: EstadoRefinamiento.PAGO_FINAL_REFINAMIENTO_COMPROBADO,
    pregunta1Arcos: "",
    pregunta2RestriccionDental: '',
    pregunta2dientes: "",
    pregunta3Aditamentos: '',
    pregunta3dientes: "",
    pregunta4aMantenerI: '',
    pregunta4aMantenerD: '',
    pregunta4bMejorarRelacionI: '',
    pregunta4bMejorarRelacionD: '',
    pregunta4cCorreccionI: '',
    pregunta4cCorreccionD: '',
    pregunta4dOpcionMovimiento: "",
    pregunta4daDesgaste: '',
    pregunta4dbSimulacion: '',
    pregunta4dcDistalizacion: '',
    pregunta4deCortes: '',
    pregunta4deElasticoS: '',
    pregunta4deElasticoI: '',
    pregunta4deElasticoV: '',
    pregunta4deElasticoL: '',
    pregunta4deBotonS: '',
    pregunta4deBotonI: '',
    pregunta4deBotonV: '',
    pregunta4deBotonL: '',
    pregunta4dObservacion: '',
    pregunta5aOverjetMostrar: '',
    pregunta5bOverjetMejorar: '',
    pregunta6: "",
    pregunta6b: "",
    pregunta6c: "",
    // pregunta6aOverbiteMostrar: "",
    pregunta6caInstruirSolamenteSup: "",
    pregunta6caInstruirSolamenteInf: "",
    pregunta6baExtruirSolamenteInf: "",
    pregunta6baExtruirSolamenteSup: "",
    pregunta6bbExtruireInstruirInf: "",
    pregunta6bbExtruireInstruirSup: "",
    // pregunta6caInstruirSolamente: "",
    // pregunta6cbInstruiryExtruir: "",
    pregunta6cbInstruiryExtruirSup: "",
    pregunta6cbInstruiryExtruirInf: "",
    pregunta6dOtroObs: '',
    pregunta7: "",
    pregunta7bModificarLineaMediaSup: "",
    pregunta7bModificarLineaMediaInf: "",
    pregunta7bbObservacion: '',
    pregunta8: '',
    pregunta8bOtro: '',
    pregunta8baApinamiento: "",
    pregunta8bbVestibularizar: "",
    pregunta8bcDipAnterior: "",
    pregunta8bdDipPosteriorDer: "",
    pregunta8beDipPosteriorIzq: "",
    pregunta8caApinamiento: "",
    pregunta8cbVestibularizar: "",
    pregunta8ccDipAnterior: "",
    pregunta8cdDipPosteriorDer: "",
    pregunta8ceDipPosteriorIzq: "",
    pregunta8dExtraccion: '',
    pregunta8ddientes: "",
    pregunta9ObservacionesComplementarias: '',
    created_at: '',
    updated_at: ''
  };
  isAdmin = false;
  isDoctor = false;
  currentModal: NgbModalRef;
  pdfData: any;
  urlPdf: any;
  pdf: any;
  public Editor = ClassicEditor;

  error: boolean;
  aprobar = false;
  userData$: Observable<any>;
  radLateralData: any;
  radPanData: any;
  stl1Data: any;
  stl2Data: any;
  stl3Data: any;
  stl4Data: any;
  stl5Data: any;
  archivos: ArchivoRefinamiento[]= [];
  fotoSuperiorData: any;
  fotoSuperior: any;
  fotoInferiorData: any;
  fotoInferior: any;
  fotoDerechaData: any;
  fotoDerecha: any;
  fotoIzquierdarData: any;
  fotoIzquierda: any;
  frenteIntraoralData: any;
  frenteIntraoral: any;
  frenteFacial: any;
  frenteFacialData: any;
  frenteFacialSonrisa: any;
  frenteFacialSonrisaData: any;
  fotografiaPerfilFacial: any;
  fotografiaPerfilFacialData: any;
  urlFotoSuperior: any;
  urlFotoIzquierda: any;
  urlFrenteIntraoral: any;
  urlFotoDerecha: any;
  urlFotoInferior: any;
  urlFrenteFacial: any;
  urlFrenteFacialSonrisa: any;
  urlPerfilFacial: any;
  shouldPrepareInfo: Subject<boolean> = new Subject();


  constructor(
    private actRoute: ActivatedRoute,
    private apiRefinamiento: RefinamientosapiService,
    private router: Router,
    private apiArchivo: ArchivorefinamientoapiService,
    private store: Store<{ user: UserState }>,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>
  ) {

    this.userData$ = this.store.select(store => store.user.userData);
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getRefinamiento(data.id);
      }
    });
  }

  getRefinamiento(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo refinamiento..." }));

    this.apiRefinamiento.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.archivos = data.archivos;

        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }


  ngOnInit() {
    this.userData$.subscribe(data => {
      this.checkAdminPermission(data);
    })
  }
  
  guardarDatos() {
    this.shouldPrepareInfo.next(true);

    // console.log(this.plantratamiento);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando formulario..." }));

    this.apiRefinamiento.updateRefinamiento(this.refinamiento).subscribe(listaPlanTratamientos => {
      this.storeLoading.dispatch(showLoading({ loadingText: "Preparando imagenes para enviar..." }));
      this.enviarImagenes();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarImagenes() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando radiografía Lateral..." }));
    await this.enviarImagen(this.radLateralData, 'rad-lateral', TipoArchivo.RADIOGRAFIAS);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando radiografía panorámica..." }));
    await this.enviarImagen(this.radPanData, 'rad-panoramica', TipoArchivo.RADIOGRAFIAS);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 1/5..." }));
    await this.enviarImagen(this.stl1Data, 'stl-1', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 2/5..." }));
    await this.enviarImagen(this.stl2Data, 'stl-2', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 3/5..." }));
    await this.enviarImagen(this.stl3Data, 'stl-3', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 4/5..." }));
    await this.enviarImagen(this.stl4Data, 'stl-4', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 5/5..." }));
    await this.enviarImagen(this.stl5Data, 'stl-5', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto superior..." }));
    await this.enviarImagen(this.fotoSuperiorData, 'foto-superior',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto inferior..." }));
    await this.enviarImagen(this.fotoInferiorData, 'foto-inferior',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto izquierda..." }));
    await this.enviarImagen(this.fotoIzquierdarData, 'foto-izquierda',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto derecha..." }));
    await this.enviarImagen(this.fotoDerechaData, 'foto-derecha',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Frente intraoral..." }));
    await this.enviarImagen(this.frenteIntraoralData, 'frente-intraoral',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Frente facial..." }));
    await this.enviarImagen(this.frenteFacialData, 'frente-facial',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Frente Facial Sonrisa..." }));
    await this.enviarImagen(this.frenteFacialSonrisaData, 'frente-facial-sonrisa',TipoArchivo.FOTOGRAFIA_PACIENTE);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Perfil Facial..." }));
    await this.enviarImagen(this.fotografiaPerfilFacialData, 'perfil-facial',TipoArchivo.FOTOGRAFIA_PACIENTE);

    if (this.aprobar) {
      this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Solicitud para aprobación..." }));
      await this.enviarSolicitudAprobacion();
    }
    this.storeLoading.dispatch(hideLoading());
    this.redirectToList();
  }
  async enviarSolicitudAprobacion() {
    await this.apiRefinamiento.solicitarAprobacionStep3(this.refinamiento).toPromise();
  }

  async enviarImagen(fileData, identifier, tipoArchivo) {
    if (fileData) {
      const myFormData = new FormData();
      myFormData.append('file', fileData)
      myFormData.append('tipoArchivo', tipoArchivo);
      myFormData.append('step', EstadoRefinamiento.REFINAMIENTO_SOLICITADO.toString());
      myFormData.append('identificador', identifier);
      // console.log(fileData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.refinamiento.id).toPromise();
      if (data) {
        // console.log('archivo enviado correctamente');
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();

    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
  }
  solicitarTratamiento() {
    this.aprobar = true;
    this.guardarDatos();
  }
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
    this.isDoctor = userHasRole('Doctor', userData);
    // console.log(this.isDoctor);
  }

  openSolicitarAprobacion(content) {
    this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.currentModal.result.then((result) => {

    }, (reason) => {

    });
  }
  enviarAprobacion() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo resultados..." }));
    this.apiRefinamiento.resultadosEnviados(this.refinamiento).subscribe(caso => {
      this.refinamiento = caso;
      this.storeLoading.dispatch(showLoading({ loadingText: "Cargando PDF..." }));
      this.enviarPdf();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());

    });
  }
  async enviarPdf() {
    if (this.pdfData) {
      const myFormData = new FormData();
      myFormData.append('file', this.pdfData)
      myFormData.append('tipoArchivo', TipoArchivo.PDF.toString());
      myFormData.append('step', EstadoCaso.CASO_ENTREGADO.toString());
      myFormData.append('identificador', 'pdf-caso');
      // console.log(this.pdfData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.refinamiento.id).toPromise();
      if (data) {
        // console.log('archivo enviado correctamente');
        this.storeLoading.dispatch(hideLoading());
        this.currentModal.close();
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
    this.redirectToList();
  }
  seleccionarPdf(e) {
    this.pdfData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlPdf = reader.result;
      }
    }
  }
  onFormChanged(e: PlanFormChanges) {
    this.refinamiento = { ...this.refinamiento, ...e.planForm };
    this.radLateralData = e.radLateralData;
    this.radPanData = e.radPanData;
    this.stl1Data = e.stl1Data;
    this.stl2Data = e.stl2Data;
    this.stl3Data = e.stl3Data;
    this.stl4Data = e.stl4Data;
    this.stl5Data = e.stl5Data;
  }
  seleccionaFotoSuperior(e) {
    this.fotoSuperiorData = e.target.files[0];
    // console.log(this.fotoSuperiorData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoSuperior = reader.result;
      }
    }

  }
  seleccionarFotoInferior(e) {
    this.fotoInferiorData = e.target.files[0];
    // console.log(this.fotoInferiorData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoInferior = reader.result;
      }
    }

  }
  seleccionarFotoDerecha(e) {
    this.fotoDerechaData = e.target.files[0];
    // console.log(this.fotoDerechaData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoDerecha = reader.result;
      }
    }

  }
  seleccionarFotoIzquierda(e) {
    this.fotoIzquierdarData = e.target.files[0];
    // console.log(this.fotoIzquierdarData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoIzquierda = reader.result;
      }
    }

  }
  seleccionaFrenteIntraoral(e) {
    this.frenteIntraoralData = e.target.files[0];
    // console.log(this.frenteIntraoralData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFrenteIntraoral = reader.result;
      }
    }

  }
  seleccionaFrenteFacial(e) {
    this.frenteFacialData = e.target.files[0];
    // console.log(this.frenteFacialData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFrenteFacial = reader.result;
      }
    }

  }
  seleccionaFrenteFacialSonrisa(e) {
    this.frenteFacialSonrisaData = e.target.files[0];
    // console.log(this.frenteFacialSonrisaData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFrenteFacialSonrisa = reader.result;
      }
    }

  }
  seleccionaFotografiaPerfilFacial(e) {
    this.fotografiaPerfilFacialData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlPerfilFacial = reader.result;
      }
    }

    // console.log(this.fotografiaPerfilFacialData);
  }
  archivoExiste(identificador: string) {
    return this.archivos.filter(archivo => archivo.identificador == identificador).length > 0;
  }
  getFileUrl(file, extension) {
      return environment.baseUrl + '/files/archivosrefinamiento/' + this.refinamiento.id + '/' + file + '.' + extension;
    
  }
}
