import { Component, OnInit } from '@angular/core';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { Store } from '@ngrx/store';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Refinamiento } from '../../../models/Refinamiento';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-vercomprobanteinicial',
  templateUrl: './vercomprobanteinicial.component.html',
  styleUrls: ['./vercomprobanteinicial.component.scss']
})
export class VercomprobanteinicialComponent implements OnInit {

  error: boolean;
  refinamiento: Refinamiento;
  refinamientoId: string;
  modalRechazar: any;
  constructor(
    private actRoute: ActivatedRoute,
    private api: RefinamientosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.refinamientoId = data.id;
        this.getRefinamiento(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getRefinamiento(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del refinamiento..." }));

    this.api.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getImageUrl(file: string) {
    return environment.baseUrl + '/files/archivosrefinamiento/' + this.refinamientoId + '/' + file + '.jpg';
  }
  aprobarComprobante() {
    this.api.aprobarRefinamientoStep2(this.refinamiento).subscribe(data => {
      // console.log(data);
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazarComprobante() {
    this.api.rechazarComprobanteStep2(this.refinamiento).subscribe(data => {
      // console.log(data);
      this.modalRechazar.close();
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }
}
