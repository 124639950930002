import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { PlanTratamiento, PlanTratamientoSingleResponse, PlanTratamientoResponse } from '../models/PlanTratamiento';
@Injectable({
  providedIn: 'root'
})
export class PlanestratamientoapiService extends BaseService{

  
  createPlanTratamiento(doctor: PlanTratamiento) {
    return this.postBaseRequest<PlanTratamientoSingleResponse>('api/planestratamiento', doctor)
      .pipe(map(data => data.data));
  }
  updatePlanTratamiento(doctor: PlanTratamiento) {
    return this.putBaseRequest<PlanTratamientoSingleResponse>('api/planestratamiento/' + doctor.id, doctor)
      .pipe(map(data => data.data));
  }
  deletePlanTratamiento(id) {
    return this.deleteBaseRequest<PlanTratamientoSingleResponse>('api/planestratamiento/' + id)
      .pipe(map(data => data.res));
  }

  getListaPlanestratamiento() {
    return this.getBaseRequest<PlanTratamientoResponse>('api/planestratamiento')
      .pipe(map(data => data.data));
  }
  getPlanTratamiento(id: string) {
    return this.getBaseRequest<PlanTratamientoSingleResponse>('api/planestratamiento/' + id)
      .pipe(map(data => data.data));
  }



}
