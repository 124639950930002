import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {
  constructor() { }
  handleData(data: any) {
    sessionStorage.setItem('userData', JSON.stringify(data));
  }
  getUserData() {
    const data = sessionStorage.getItem('userData');
    if (data) {
      return JSON.parse(sessionStorage.getItem('userData'));
    } else {
      return null;
    }
  }

  isUserDataSaved() {
    const data = sessionStorage.getItem('userData');
    if (data) {
      return true;
    }
    return false;
  }

  
  removeUserData(){
    sessionStorage.removeItem('userData');
  }
}
