import { Component, OnInit } from '@angular/core';
import { PlanTratamiento } from 'src/app/models/PlanTratamiento';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanestratamientoapiService } from '../../../services/planestratamientoapi.service';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { EstadoCaso, Caso } from 'src/app/models/Caso';
import { ArchivoCasoapiService } from 'src/app/services/archivocasoapi.service';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { userHasRole } from 'src/app/models/User';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from '../../../redux/reducers/user.reducer';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { PlanFormChanges } from '../../../models/PlanTratamiento';
import { BaseArchivo } from '../../../models/Archivocaso';

@Component({
  selector: 'app-formplanestratamiento',
  templateUrl: './formplanestratamiento.component.html',
  styleUrls: ['./formplanestratamiento.component.scss']
})
export class FormplanestratamientoComponent implements OnInit {
  estadosCaso = EstadoCaso;
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  isAdmin = false;
  isDoctor = false;
  isSolicitarVisible = false;
  currentModal: NgbModalRef;
  pdfData: any;
  urlPdf: any;
  pdf: any;
  public Editor = ClassicEditor;
  plantratamiento: PlanTratamiento = {
    id: 0,
    pregunta1Arcos: "",
    pregunta2RestriccionDental: '',
    pregunta2dientes: "",
    pregunta3Aditamentos: '',
    pregunta3dientes: "",
    pregunta4aMantenerI: '',
    pregunta4aMantenerD: '',
    pregunta4bMejorarRelacionI: '',
    pregunta4bMejorarRelacionD: '',
    pregunta4cCorreccionI: '',
    pregunta4cCorreccionD: '',
    pregunta4dOpcionMovimiento: "",
    pregunta4daDesgaste: '',
    pregunta4dbSimulacion: '',
    pregunta4dcDistalizacion: '',
    pregunta4deCortes: '',
    pregunta4deElasticoS: '',
    pregunta4deElasticoI: '',
    pregunta4deElasticoV: '',
    pregunta4deElasticoL: '',
    pregunta4deBotonS: '',
    pregunta4deBotonI: '',
    pregunta4deBotonV: '',
    pregunta4deBotonL: '',
    pregunta4dObservacion: '',
    pregunta5aOverjetMostrar: '',
    pregunta5bOverjetMejorar: '',
    pregunta6: "",
    pregunta6b: "",
    pregunta6c: "",
    // pregunta6aOverbiteMostrar: "",
    pregunta6caInstruirSolamenteSup: "",
    pregunta6caInstruirSolamenteInf: "",
    pregunta6baExtruirSolamenteInf: "",
    pregunta6baExtruirSolamenteSup: "",
    pregunta6bbExtruireInstruirInf: "",
    pregunta6bbExtruireInstruirSup: "",
    // pregunta6caInstruirSolamente: "",
    // pregunta6cbInstruiryExtruir: "",
    pregunta6cbInstruiryExtruirSup: "",
    pregunta6cbInstruiryExtruirInf: "",
    pregunta6dOtroObs: '',
    pregunta7: "",
    pregunta7bModificarLineaMediaSup: "",
    pregunta7bModificarLineaMediaInf: "",
    pregunta7bbObservacion: '',
    pregunta8: '',
    pregunta8bOtro: '',
    pregunta8baApinamiento: "",
    pregunta8bbVestibularizar: "",
    pregunta8bcDipAnterior: "",
    pregunta8bdDipPosteriorDer: "",
    pregunta8beDipPosteriorIzq: "",
    pregunta8caApinamiento: "",
    pregunta8cbVestibularizar: "",
    pregunta8ccDipAnterior: "",
    pregunta8cdDipPosteriorDer: "",
    pregunta8ceDipPosteriorIzq: "",
    pregunta8dExtraccion: '',
    pregunta8ddientes: "",
    pregunta9ObservacionesComplementarias: '',
    caso_id: 0,
    created_at: '',
    updated_at: ''
  };
  error: boolean;
  radLateralData: any;
  radLateral: any;
  radPanData: any;
  radPan: any;
  stl1Data: any;
  stl2Data: any;
  stl3Data: any;
  stl4Data: any;
  stl5Data: any;
  aprobar = false;
  
  userData$: Observable<any>;
  shouldPrepareInfo: Subject<boolean> = new Subject();
  archivos: BaseArchivo[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private api: PlanestratamientoapiService,
    private apiCaso: CasosapiService,
    private router: Router,
    private apiArchivo: ArchivoCasoapiService,
    private store: Store<{ user: UserState }>,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>
  ) {

    this.userData$ = this.store.select(store => store.user.userData);
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCaso(data.id);
      } else {
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.PlanTratamiento.fechaNacimiento);
      }
    });
  }

  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.apiCaso.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.validateSolicitar();
        if(this.caso.plantratamiento){
          this.plantratamiento = this.caso.plantratamiento;
          this.archivos = this.caso.archivos;
        }
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  
  ngOnInit() {
    this.userData$.subscribe(data => {
      this.checkAdminPermission(data);
    })
  }
  guardarDatos() {
    this.shouldPrepareInfo.next(true);
    // console.log(this.plantratamiento);
  }
  async enviarImagenes() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando radiografía Lateral..." }));
    await this.enviarImagen(this.radLateralData, 'rad-lateral', TipoArchivo.RADIOGRAFIAS);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando radiografía panorámica..." }));
    await this.enviarImagen(this.radPanData, 'rad-panoramica', TipoArchivo.RADIOGRAFIAS);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 1/5..." }));
    await this.enviarImagen(this.stl1Data, 'stl-1', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 2/5..." }));
    await this.enviarImagen(this.stl2Data, 'stl-2', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 3/5..." }));
    await this.enviarImagen(this.stl3Data, 'stl-3', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 4/5..." }));
    await this.enviarImagen(this.stl4Data, 'stl-4', TipoArchivo.ARCHIVO_STL);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando archivos STL 5/5..." }));
    await this.enviarImagen(this.stl5Data, 'stl-5', TipoArchivo.ARCHIVO_STL);
    if (this.aprobar) {
      this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Solicitud para aprobación..." }));
      await this.enviarSolicitudAprobacion();
    }
    this.storeLoading.dispatch(hideLoading());
    this.redirectToList();
  }
  async enviarSolicitudAprobacion() {
    await this.apiCaso.solicitarAprobacionStep3(this.caso).toPromise();
  }

  async enviarImagen(fileData, identifier, tipoArchivo) {
    if (fileData) {
      const myFormData = new FormData();
      myFormData.append('file', fileData)
      myFormData.append('tipoArchivo', tipoArchivo);
      myFormData.append('step', EstadoCaso.TRATAMIENTO_SOLICITADO.toString());
      myFormData.append('identificador', identifier);
      // console.log(fileData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.caso.id).toPromise();
      if (data) {
        // console.log('archivo enviado correctamente');
      }
      // this.redirectToList();

    } else {
      // this.redirectToList();
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
  }
  solicitarTratamiento() {
    this.aprobar = true;
    this.guardarDatos();
  }
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
    this.isDoctor = userHasRole('Doctor', userData);
    // console.log(this.isDoctor);
  }


  openSolicitarAprobacion(content) {
    this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.currentModal.result.then((result) => {

    }, (reason) => {

    });
  }
  enviarAprobacion() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo resultados..." }));
    this.apiCaso.resultadosEnviados(this.caso).subscribe(caso => {
      this.caso = caso;
      this.storeLoading.dispatch(showLoading({ loadingText: "Cargando PDF..." }));
      this.enviarPdf();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarPdf() {
    if (this.pdfData) {
      const myFormData = new FormData();
      myFormData.append('file', this.pdfData)
      myFormData.append('tipoArchivo', TipoArchivo.PDF.toString());
      myFormData.append('step', EstadoCaso.CASO_ENTREGADO.toString());
      myFormData.append('identificador', 'pdf-caso');
      // console.log(this.pdfData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.caso.id).toPromise();
      if (data) {
        // console.log('archivo enviado correctamente');
        this.currentModal.close();
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
    this.redirectToList();
  }
  seleccionarPdf(e) {
    this.pdfData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlPdf = reader.result;
      }
    }
  }
  onFormChanged(e: PlanFormChanges){
    this.plantratamiento = {...this.plantratamiento, ...e.planForm, id: this.plantratamiento.id};
    this.radLateralData = e.radLateralData;
    this.radPanData = e.radPanData;
    this.stl1Data = e.stl1Data;
    this.stl2Data = e.stl2Data;
    this.stl3Data = e.stl3Data;
    this.stl4Data = e.stl4Data;
    this.stl5Data = e.stl5Data;
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando formulario..." }));
    console.log("enviando formulario", this.plantratamiento.id);
    if (this.plantratamiento.id === 0) {
      this.plantratamiento.caso_id = this.caso.id;
      this.api.createPlanTratamiento(this.plantratamiento).subscribe(listaPlanTratamientos => {
        this.storeLoading.dispatch(showLoading({ loadingText: "Preparando imagenes para enviar..." }));
        this.enviarImagenes();
      }, (error) => {
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.api.updatePlanTratamiento(this.plantratamiento).subscribe(listaPlanTratamientos => {
        this.storeLoading.dispatch(showLoading({ loadingText: "Preparando imagenes para enviar..." }));
        this.enviarImagenes();
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }
  validateSolicitar(){
    this.isSolicitarVisible = this.caso.status < EstadoCaso.TRATAMIENTO_SOLICITADO && this.caso.status >= EstadoCaso.PAGO_RECHAZADO_POR_DOCTOR;
  }
}
