import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-diferencias-paciente',
  templateUrl: './diferencias-paciente.component.html',
  styleUrls: ['./diferencias-paciente.component.scss']
})
export class DiferenciasPacienteComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  constructor() { }

  ngOnInit() {
  }

}
