import { Component, OnInit, ViewChild } from '@angular/core';
import { ContenidosapiService } from '../../../services/contenidosapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  principalDescripcion: string;
  constructor(private cApi: ContenidosapiService, private actRoute: ActivatedRoute) {
    actRoute.params.subscribe(data => {
      this.getContenidos();
    });
  }

  ngOnInit() {
  }
  getContenidos() {
    this.cApi.getContenido('PRINCIPAL_DESCRIPCION').subscribe(data => {
      if (data) {
        this.principalDescripcion = data.value;
      }
    });
  }
}
