import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-public-menu',
  templateUrl: './public-menu.component.html',
  styleUrls: ['./public-menu.component.scss']
})
export class PublicMenuComponent implements OnInit {

  @Input() about: any;
  @Input() contact: any;
  constructor() { }

  ngOnInit() {
    // console.log(this.about);
    // console.log(this.contact);
  }

  scroll(el: any) {
    // console.log(el);
    el.rootElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

}
