import { Component, OnInit } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenService } from 'src/app/services/token.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { logout } from '../../redux/actions/user.actions';
import { Observable } from 'rxjs';
import { UserdataService } from '../../services/userdata.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  currentModal: NgbModalRef;
  userName: String;
  userData$: Observable<any>;
  constructor(
    private modalService: NgbModal,
    private token: TokenService,
    private router: Router,
    private store: Store<{ user: UserState }>,
    private userData: UserdataService


  ) { }

  ngOnInit() {
    this.userData$ = this.store.select(store => store.user.userData);
    this.userData$.subscribe(data => {
      if (!data) {
        return;
      }
      // console.log(data);
      this.userName = data.name;
    });
  }
  abrirCerrarSesion(content) {
    this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.currentModal.result.then((result) => {

    }, (reason) => {

    });

  }
  cerrarSesion() {
    this.token.remove();
    this.store.dispatch(logout());
    this.currentModal.close();
    this.router.navigateByUrl('/');
    this.userData.removeUserData();
  }
}
