import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Boca, PosicionBoca } from 'src/app/models/Dientes';

@Component({
  selector: 'app-dienteselector',
  templateUrl: './dienteselector.component.html',
  styleUrls: ['./dienteselector.component.scss']
})
export class DienteselectorComponent implements OnInit {
  @Input() disabled: boolean = false
  @Input() boca: Boca = {
    tl: [
      { numero: '1.8', selected: false, posicionboca: PosicionBoca.TL, index: 0 },
      { numero: '1.7', selected: false, posicionboca: PosicionBoca.TL, index: 1 },
      { numero: '1.6', selected: false, posicionboca: PosicionBoca.TL, index: 2 },
      { numero: '1.5', selected: false, posicionboca: PosicionBoca.TL, index: 3 },
      { numero: '1.4', selected: false, posicionboca: PosicionBoca.TL, index: 4 },
      { numero: '1.3', selected: false, posicionboca: PosicionBoca.TL, index: 5 },
      { numero: '1.2', selected: false, posicionboca: PosicionBoca.TL, index: 6 },
      { numero: '1.1', selected: false, posicionboca: PosicionBoca.TL, index: 7 },
    ],
    tr: [
      { numero: '2.1', selected: false, posicionboca: PosicionBoca.TR, index: 0 },
      { numero: '2.2', selected: false, posicionboca: PosicionBoca.TR, index: 1 },
      { numero: '2.3', selected: false, posicionboca: PosicionBoca.TR, index: 2 },
      { numero: '2.4', selected: false, posicionboca: PosicionBoca.TR, index: 3 },
      { numero: '2.5', selected: false, posicionboca: PosicionBoca.TR, index: 4 },
      { numero: '2.6', selected: false, posicionboca: PosicionBoca.TR, index: 5 },
      { numero: '2.7', selected: false, posicionboca: PosicionBoca.TR, index: 6 },
      { numero: '2.8', selected: false, posicionboca: PosicionBoca.TR, index: 7 },
    ],
    bl: [
      { numero: '4.8', selected: false, posicionboca: PosicionBoca.BL, index: 0 },
      { numero: '4.7', selected: false, posicionboca: PosicionBoca.BL, index: 1 },
      { numero: '4.6', selected: false, posicionboca: PosicionBoca.BL, index: 2 },
      { numero: '4.5', selected: false, posicionboca: PosicionBoca.BL, index: 3 },
      { numero: '4.4', selected: false, posicionboca: PosicionBoca.BL, index: 4 },
      { numero: '4.3', selected: false, posicionboca: PosicionBoca.BL, index: 5 },
      { numero: '4.2', selected: false, posicionboca: PosicionBoca.BL, index: 6 },
      { numero: '4.1', selected: false, posicionboca: PosicionBoca.BL, index: 7 },
    ],
    br: [
      { numero: '3.1', selected: false, posicionboca: PosicionBoca.BR, index: 0 },
      { numero: '3.2', selected: false, posicionboca: PosicionBoca.BR, index: 1 },
      { numero: '3.3', selected: false, posicionboca: PosicionBoca.BR, index: 2 },
      { numero: '3.4', selected: false, posicionboca: PosicionBoca.BR, index: 3 },
      { numero: '3.5', selected: false, posicionboca: PosicionBoca.BR, index: 4 },
      { numero: '3.6', selected: false, posicionboca: PosicionBoca.BR, index: 5 },
      { numero: '3.7', selected: false, posicionboca: PosicionBoca.BR, index: 6 },
      { numero: '3.8', selected: false, posicionboca: PosicionBoca.BR, index: 7 },
    ]
  };
  cantidadSeleccionados: number;
  @Output() dientesSelected = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  dienteSelected(e) {
    // console.log(e);
    // console.log(this.boca);
    this.dientesSelected.emit({ boca: this.boca });
  }

}
