import { BaseFormPlan } from './PlanTratamiento';
import { ArchivoRefinamiento } from './Archivorefinamiento';
export interface Refinamiento extends BaseFormPlan {
    id: number;
    motivoEnvio: string;
    motivoOtroExplique: string;
    nroAlineacionSuperior: string;
    nroAlineacionInferior: string;
    status: EstadoRefinamiento;
    motivo_rechazo?: string;
    precio_tratamiento_bs?: number;
    descripcion_tratamiento?: string;
    direccion_entrega?: string;
    latitudEntrega?: string;
    longitudEntrega?: string;
    video_entrega?: string;
    reunionZoom?: string;
    archivos?: ArchivoRefinamiento[];
}
export interface RefinamientoResponse {
    res: string;
    data: Refinamiento[];
}
export interface RefinamientoSingleResponse {
    res: string;
    data: Refinamiento;
}
export enum EstadoRefinamiento {
    REFINAMIENTO_SOLICITADO = 0,
    PAGO_INICIAL_ENVIADO = 1,
    PAGO_INICIAL_VERIFICADO = 2,
    FORMULARIO_REFINAMIENTO_ENVIADO = 3,
    REFINAMIENTO_APROBADO_PAGO_SOLICITADO = 4,
    PAGO_FINAL_REFINAMIENTO_REALIZADO = 5,
    PAGO_FINAL_REFINAMIENTO_COMPROBADO = 6,
    REFINAMIENTO_ENTREGADO = 7,
    REFINAMIENTO_ENTREGADO_DIRECCION = 8,
    PAGO_INICIAL_ERRONEO = -1,
    PAGO_RECHAZADO_POR_DOCTOR = -6,
    PAGO_REFINAMIENTO_ERRONEO = -7,
    REFINAMIENTO_ENTREGADO_RECHAZADO = -9
}
export enum EstadoRefinamientoText {
    'Refinamiento Solicitada' = 0,
    'Pago Inicial Enviado' = 1,
    'Pago Inicial Verificado' = 2,
    'Formulario de Refinamiento Enviado' = 3,
    'Refinamiento aprobado pago solicitado' = 4,
    'Pago final del refinamiento realizado' = 5,
    'Pago final de refinamiento comprobado' = 6,
    'Refinamiento entregado' = 7,
    'Refinamiento entregado a dirección especificada' = 8,
    'Pago Inicial Erróneo' = -1,
    'El pago no pudo ser verificado' = -3,
    'Pago rechazado por doctor' = -6,
    'El pago del refinamiento no pudo ser verificado' = -7,
    'Doctor rechazó refinamiento entregado' = -8
}