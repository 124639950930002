import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ArchivoCasoapiService } from 'src/app/services/archivocasoapi.service';
import { isFileImage } from '../../../utilities/FileUtilities';

@Component({
  selector: 'app-multifile',
  templateUrl: './multifile.component.html',
  styleUrls: ['./multifile.component.scss']
})
export class MultifileComponent implements OnInit {
  @Output() selectedFile = new EventEmitter();
  files: any[] = [];
  filesData: any[] = [];
  urls: any[] = []
  constructor() { }

  ngOnInit() {
  }
  seleccionarArchivo(e, index) {
    if (e.target.files && e.target.files[0]) {
      this.filesData[index] = e.target.files[0];
      var reader = new FileReader();
      if (isFileImage(e.target.files[0])) {

        reader.readAsDataURL(e.target.files[0]); // read file as data url

        reader.onload = (ev) => { // called once readAsDataURL is completed
          this.urls[index] = reader.result;
        }
      }

      // console.log(this.filesData[index]);
      this.selectedFile.emit({ files: this.filesData });
    }
  }
  eliminarArchivo(index) {
    this.files.splice(index, 1);
    this.filesData.splice(index, 1);
    this.urls.splice(index, 1);
    this.selectedFile.emit({ files: this.filesData });
  }
  agregarArchivo() {
    this.files.push({});
    this.filesData.push({});
    this.urls.push(null);
  }
}
