import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Refinamiento, EstadoRefinamiento, EstadoRefinamientoText } from '../../../models/Refinamiento';
import { getHumanDateTimeFromString } from '../../../utilities/DateUtilities';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';

@Component({
  selector: '[app-refinamientoitem]',
  templateUrl: './refinamientoitem.component.html',
  styleUrls: ['./refinamientoitem.component.scss']
})
export class RefinamientoitemComponent implements OnInit {
  estadosRefinamiento = EstadoRefinamiento;

  @Input() refinamiento: Refinamiento;
  @Input() isDoctor: boolean;
  @Input() isAdmin: boolean;
  @Output() onRefinamientoChanged = new EventEmitter<string>();
  constructor(private storeLoading: Store<LoadingState>,
    private apiRefinamientos: RefinamientosapiService,

  ) { }

  ngOnInit() {
  }
  getFechaCreacion(fecha) {
    return getHumanDateTimeFromString(fecha);
  }

  getMotivoEnvio(refinamiento: Refinamiento) {
    switch (parseInt(refinamiento.motivoEnvio)) {
      case 0:
        return "Mejoras de finalizado";
      case 1:
        return "Dientes no siguen alineador";
      case 2:
        return "Cambio de plan de tratamiento";
      case 3:
        return "Paciente tiene nuevas restauraciones";
      case 4:
        return "Paciente no cumplió con uso";
      case 5:
        return "Otro " + refinamiento.motivoOtroExplique;
    }
    return "Ninguno";
  }
  getEstadoRefinamiento(refinamiento: Refinamiento) {
    return EstadoRefinamientoText[refinamiento.status];
  }
  entregarRefinamiento(refinamiento: Refinamiento) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Entregando refinamiento..." }));
    this.apiRefinamientos.entregarRefinamiento(refinamiento).subscribe(data => {
      this.storeLoading.dispatch(hideLoading());
      // console.log(data);
      this.onRefinamientoChanged.emit('reload');        
    }, (error) => {
      console.log(error);
    });
  }
  
  eliminarRefinamiento(id) {
    // console.log(id);
    this.storeLoading.dispatch(showLoading({ loadingText: "Eliminando refinamiento..." }));
    this.apiRefinamientos.deleteRefinamiento(id).subscribe(res => {
      this.storeLoading.dispatch(hideLoading());
      if (res !== 'success') {
        console.log(res);
      } else {
        this.onRefinamientoChanged.emit('reload');        
      }
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  getGoogleMapsLink() {
    return "http://www.google.com/maps/place/" + this.refinamiento.latitudEntrega + "," + this.refinamiento.longitudEntrega;
  }

}
