import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pacientes-public',
  templateUrl: './pacientes-public.component.html',
  styleUrls: ['./pacientes-public.component.scss']
})
export class PacientesPublicComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  constructor() { }

  ngOnInit() {
  }

}
