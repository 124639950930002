import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';
import { MenuComponent } from './components/common/menu/menu.component';
import { ListacasosComponent } from './pages/casos/listacasos/listacasos.component';
import { FormcasoComponent } from './pages/casos/formcaso/formcaso.component';
import { ListadoctoresComponent } from './pages/doctores/listadoctores/listadoctores.component';
import { FormdoctoresComponent } from './pages/doctores/formdoctores/formdoctores.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormplanestratamientoComponent } from './pages/planestratamiento/formplanestratamiento/formplanestratamiento.component';
import { DienteselectorComponent } from './components/planestratamiento/dienteselector/dienteselector.component';
import { DienteComponent } from './components/planestratamiento/diente/diente.component';
import { CompanypageComponent } from './pages/companypage/companypage.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PublicMenuComponent } from './components/public/public-menu/public-menu.component';
import { HomeImageComponent } from './components/public/home-image/home-image.component';
import { AboutComponent } from './components/public/about/about.component';
import { OrtoLayoutComponent } from './layouts/orto-layout/orto-layout.component';
import { PacienteLayoutComponent } from './layouts/paciente-layout/paciente-layout.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterCommonComponent } from './components/public/footer/footer-common.component';
import { LoginComponent } from './components/public/login/login.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { OrthoMenuComponent } from './components/ortho/ortho-menu/ortho-menu.component';
import { DoctoresPublicComponent } from './pages/ortho/doctores-public/doctores-public.component';
import { OrthoHomeImageComponent } from './components/ortho/ortho-home-image/ortho-home-image.component';
import { EmpresaComponent } from './pages/ortho/empresa/empresa.component';
import { AcreditacionComponent } from './pages/ortho/acreditacion/acreditacion.component';
import { DiferenciasComponent } from './pages/ortho/diferencias/diferencias.component';
import { EnviarCasoComponent } from './pages/ortho/enviar-caso/enviar-caso.component';
import { AcreditacionRegistroComponent } from './components/ortho/acreditacion-registro/acreditacion-registro.component';
import { PacienteMenuComponent } from './components/paciente/paciente-menu/paciente-menu.component';
import { PacienteHomeImageComponent } from './components/paciente/paciente-home-image/paciente-home-image.component';
import { PacientesPublicComponent } from './pages/paciente/pacientes-public/pacientes-public.component';
import { ComoFuncionaComponent } from './pages/paciente/como-funciona/como-funciona.component';
import { DiferenciasPacienteComponent } from './pages/paciente/diferencias-paciente/diferencias-paciente.component';
import { EncontrarDoctorComponent } from './pages/paciente/encontrar-doctor/encontrar-doctor.component';
import { Paso1Component } from './pages/casos/paso1/paso1.component';
import { Paso2Component } from './pages/casos/paso2/paso2.component';
import { Verpaso2Component } from './pages/casos/verpaso2/verpaso2.component';
import { Paso4Component } from './pages/casos/paso4/paso4.component';
import { FormcontenidosComponent } from './pages/contenidos/formcontenidos/formcontenidos.component';
import { ListacontenidosComponent } from './pages/contenidos/listacontenidos/listacontenidos.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormfotosComponent } from './pages/fotos/formfotos/formfotos.component';
import { ListafotosComponent } from './pages/fotos/listafotos/listafotos.component';
import { SolicitarinformacionComponent } from './components/planestratamiento/solicitarinformacion/solicitarinformacion.component';
import { ListasolicitudesComponent } from './pages/solicitudes/listasolicitudes/listasolicitudes.component';
import { VersolicitudComponent } from './pages/solicitudes/versolicitud/versolicitud.component';
import { MultifileComponent } from './components/common/multifile/multifile.component';
import { StoreModule } from '@ngrx/store';
import { UserReducer } from './redux/reducers/user.reducer';
import { Verpaso4Component } from './pages/casos/verpaso4/verpaso4.component';
import { EnviarresultadosComponent } from './pages/casos/enviarresultados/enviarresultados.component';
import { RecibirresultadosComponent } from './pages/casos/recibirresultados/recibirresultados.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingReducer } from './redux/reducers/loading.reducer';
import { VerarchivosComponent } from './pages/casos/verarchivos/verarchivos.component';
import {GoogleMapsModule} from '@angular/google-maps'; 
import { DataTablesModule } from 'angular-datatables';
import { AuthInterceptor } from './interceptors/AuthInterceptor';
import { VerformtratamientoComponent } from './pages/casos/verformtratamiento/verformtratamiento.component';
import { VerpacienteComponent } from './pages/casos/verpaciente/verpaciente.component';
import { FormmotivoComponent } from './pages/refinamiento/formmotivo/formmotivo.component';
import { FormrefinamientoComponent } from './pages/refinamiento/formrefinamiento/formrefinamiento.component';
import { FormcobrorefinamientoComponent } from './pages/refinamiento/formcobrorefinamiento/formcobrorefinamiento.component';
import { DatosCobroComponent } from './components/datos-cobro/datos-cobro.component';
import { RefinamientoitemComponent } from './components/refinamientos/refinamientoitem/refinamientoitem.component';
import { PlanformComponent } from './components/planestratamiento/planform/planform.component';
import { VercomprobanteinicialComponent } from './pages/refinamiento/vercomprobanteinicial/vercomprobanteinicial.component';
import { VerrefinamientoComponent } from './pages/refinamiento/verrefinamiento/verrefinamiento.component';
import { EnviarresultadosrefComponent } from './pages/refinamiento/enviarresultadosref/enviarresultadosref.component';
import { RecibirresultadosrefComponent } from './pages/refinamiento/recibirresultadosref/recibirresultadosref.component';
import { PagorefinamientofinalComponent } from './pages/refinamiento/pagorefinamientofinal/pagorefinamientofinal.component';
import { VerpagorefinamientoComponent } from './pages/refinamiento/verpagorefinamiento/verpagorefinamiento.component';
import { VerformrefinamientoComponent } from './pages/refinamiento/verformrefinamiento/verformrefinamiento.component';
import { TextoaceptacionComponent } from './components/textoaceptacion/textoaceptacion.component';
import { VerarchivorefComponent } from './pages/refinamiento/verarchivoref/verarchivoref.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    MenuComponent,
    ListacasosComponent,
    FormcasoComponent,
    ListadoctoresComponent,
    FormdoctoresComponent,
    FormplanestratamientoComponent,
    DienteselectorComponent,
    DienteComponent,
    CompanypageComponent,
    SiteLayoutComponent,
    AdminLayoutComponent,
    PublicMenuComponent,
    HomeImageComponent,
    AboutComponent,
    OrtoLayoutComponent,
    PacienteLayoutComponent,
    ContactComponent,
    FooterCommonComponent,
    LoginComponent,
    LoginLayoutComponent,
    OrthoMenuComponent,
    DoctoresPublicComponent,
    OrthoHomeImageComponent,
    EmpresaComponent,
    AcreditacionComponent,
    DiferenciasComponent,
    EnviarCasoComponent,
    AcreditacionRegistroComponent,
    PacienteMenuComponent,
    PacienteHomeImageComponent,
    PacientesPublicComponent,
    ComoFuncionaComponent,
    DiferenciasPacienteComponent,
    EncontrarDoctorComponent,
    Paso1Component,
    Paso2Component,
    Verpaso2Component,
    Paso4Component,
    FormcontenidosComponent,
    ListacontenidosComponent,
    FormfotosComponent,
    ListafotosComponent,
    SolicitarinformacionComponent,
    ListasolicitudesComponent,
    VersolicitudComponent,
    MultifileComponent,
    Verpaso4Component,
    EnviarresultadosComponent,
    RecibirresultadosComponent,
    LoadingComponent,
    VerarchivosComponent,
    VerformtratamientoComponent,
    VerpacienteComponent,
    FormmotivoComponent,
    FormrefinamientoComponent,
    FormcobrorefinamientoComponent,
    DatosCobroComponent,
    RefinamientoitemComponent,
    PlanformComponent,
    VercomprobanteinicialComponent,
    VerrefinamientoComponent,
    EnviarresultadosrefComponent,
    RecibirresultadosrefComponent,
    PagorefinamientofinalComponent,
    VerpagorefinamientoComponent,
    VerformrefinamientoComponent,
    TextoaceptacionComponent,
    VerarchivorefComponent
  ],
  imports: [
    BrowserModule,
    GoogleMapsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    CKEditorModule,
    DataTablesModule,
    StoreModule.forRoot({ user: UserReducer, load: LoadingReducer }),
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
