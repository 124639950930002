import { Component, OnInit } from '@angular/core';
import { SolicitudInformacion } from '../../../models/SolicitudInformacion';
import { SolicitudinformacionService } from '../../../services/solicitudinformacion.service';
import { ActivatedRoute } from '@angular/router';
import { ArchivoCaso } from '../../../models/Archivocaso';
import { environment } from '../../../../environments/environment';
import { hideLoading, showLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-listasolicitudes',
  templateUrl: './listasolicitudes.component.html',
  styleUrls: ['./listasolicitudes.component.scss']
})
export class ListasolicitudesComponent implements OnInit {


  solicitudes: SolicitudInformacion[] = [];
  casoId: string;
  constructor(private api: SolicitudinformacionService, private actRoute: ActivatedRoute,
    private storeLoading: Store<LoadingState>
    ) {
    // constructor(){
    this.actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.casoId = data.id;
        this.recargarSolicitudes();
      }

    });


  }
  recargarSolicitudes() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));
    this.api.getListaSolicitudesInformacionByCaso(this.casoId).subscribe(listaSolicitudes => {
      this.solicitudes = listaSolicitudes;
      this.storeLoading.dispatch(hideLoading());
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
  }
  eliminarSolicitud(id) {
    // console.log(id);
    this.storeLoading.dispatch(showLoading({ loadingText: "Eliminando solicitud..." }));
    this.api.deleteSolicitudInformacion(id).subscribe(res => {
      this.storeLoading.dispatch(hideLoading());
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarSolicitudes();
      }
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  getRequiereArchivos(solicitud: SolicitudInformacion) {
    if (solicitud.incluye_archivos) {
      return "Sí";
    }
    return "No";
  }
  getArchivoLink(archivo: ArchivoCaso, index) {
    return environment.baseUrl + '/files/archivoscaso/' + archivo.caso_id + '/solicitud_' + archivo.solicitud_id + '_' + index + archivo.extension + '?rand=' + Math.random();
  }
}
