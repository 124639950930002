import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { ListadoctoresComponent } from './pages/doctores/listadoctores/listadoctores.component';
import { FormdoctoresComponent } from './pages/doctores/formdoctores/formdoctores.component';
import { ListacasosComponent } from './pages/casos/listacasos/listacasos.component';
import { FormcasoComponent } from './pages/casos/formcaso/formcaso.component';
import { FormplanestratamientoComponent } from './pages/planestratamiento/formplanestratamiento/formplanestratamiento.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CompanypageComponent } from './pages/companypage/companypage.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './components/public/login/login.component';
import { OrtoLayoutComponent } from './layouts/orto-layout/orto-layout.component';
import { DoctoresPublicComponent } from './pages/ortho/doctores-public/doctores-public.component';
import { PacienteLayoutComponent } from './layouts/paciente-layout/paciente-layout.component';
import { PacientesPublicComponent } from './pages/paciente/pacientes-public/pacientes-public.component';
import { Paso1Component } from './pages/casos/paso1/paso1.component';
import { Paso2Component } from './pages/casos/paso2/paso2.component';
import { Verpaso2Component } from './pages/casos/verpaso2/verpaso2.component';
import { FormcontenidosComponent } from './pages/contenidos/formcontenidos/formcontenidos.component';
import { ListacontenidosComponent } from './pages/contenidos/listacontenidos/listacontenidos.component';
import { FormfotosComponent } from './pages/fotos/formfotos/formfotos.component';
import { ListafotosComponent } from './pages/fotos/listafotos/listafotos.component';
import { ListasolicitudesComponent } from './pages/solicitudes/listasolicitudes/listasolicitudes.component';
import { VersolicitudComponent } from './pages/solicitudes/versolicitud/versolicitud.component';
import { AuthGuardServiceService } from './services/auth-guard-service.service';
import { Paso4Component } from './pages/casos/paso4/paso4.component';
import { Verpaso4Component } from './pages/casos/verpaso4/verpaso4.component';
import { EnviarresultadosComponent } from './pages/casos/enviarresultados/enviarresultados.component';
import { RecibirresultadosComponent } from './pages/casos/recibirresultados/recibirresultados.component';
import { VerarchivosComponent } from './pages/casos/verarchivos/verarchivos.component';
import { VerformtratamientoComponent } from './pages/casos/verformtratamiento/verformtratamiento.component';
import { VerpacienteComponent } from './pages/casos/verpaciente/verpaciente.component';
import { FormmotivoComponent } from './pages/refinamiento/formmotivo/formmotivo.component';
import { FormrefinamientoComponent } from './pages/refinamiento/formrefinamiento/formrefinamiento.component';
import { FormcobrorefinamientoComponent } from './pages/refinamiento/formcobrorefinamiento/formcobrorefinamiento.component';
import { VercomprobanteinicialComponent } from './pages/refinamiento/vercomprobanteinicial/vercomprobanteinicial.component';
import { EnviarresultadosrefComponent } from './pages/refinamiento/enviarresultadosref/enviarresultadosref.component';
import { RecibirresultadosrefComponent } from './pages/refinamiento/recibirresultadosref/recibirresultadosref.component';
import { PagorefinamientofinalComponent } from './pages/refinamiento/pagorefinamientofinal/pagorefinamientofinal.component';
import { VerpagorefinamientoComponent } from './pages/refinamiento/verpagorefinamiento/verpagorefinamiento.component';
import { VerformrefinamientoComponent } from './pages/refinamiento/verformrefinamiento/verformrefinamiento.component';
import { VerarchivorefComponent } from './pages/refinamiento/verarchivoref/verarchivoref.component';


const routes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: 'home', component: CompanypageComponent },
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      { path: 'admin', component: IndexComponent, canActivate: [AuthGuardServiceService] },
      { path: 'doctores', component: ListadoctoresComponent, canActivate: [AuthGuardServiceService] },
      { path: 'doctores/create', component: FormdoctoresComponent, canActivate: [AuthGuardServiceService] },
      { path: 'doctores/:idDoctor/casos', component: ListacasosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'doctores/:id', component: FormdoctoresComponent, canActivate: [AuthGuardServiceService] },
      { path: 'contenidos', component: ListacontenidosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'contenidos/create', component: FormcontenidosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'contenidos/:id', component: FormcontenidosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'fotos', component: ListafotosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'fotos/create', component: FormfotosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'fotos/:id', component: FormfotosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos', component: ListacasosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/create', component: FormcasoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/ver/:id', component: Paso1Component, canActivate: [AuthGuardServiceService] },
      { path: 'archivos/ver/:id', component: VerarchivosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'formulario/ver/:id', component: VerformtratamientoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/comprobante-inicial/:id', component: Verpaso2Component, canActivate: [AuthGuardServiceService] },
      { path: 'casos/comprobante-tratamiento/:id', component: Verpaso4Component, canActivate: [AuthGuardServiceService] },
      { path: 'casos/enviarresultados/:id', component: EnviarresultadosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/verresultados/:id', component: RecibirresultadosComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/paso2/:id', component: Paso2Component, canActivate: [AuthGuardServiceService] },
      { path: 'casos/paso4/:id', component: Paso4Component, canActivate: [AuthGuardServiceService] },
      { path: 'casos/:id', component: FormcasoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/:id/solicitudes', component: ListasolicitudesComponent, canActivate: [AuthGuardServiceService] },
      { path: 'casos/:id/verpaciente', component: VerpacienteComponent, canActivate: [AuthGuardServiceService] },
      { path: 'solicitudes/:id', component: VersolicitudComponent, canActivate: [AuthGuardServiceService] },
      // { path: 'planestratamiento', component: ListacasosComponent },
      { path: 'planestratamiento/create', component: FormplanestratamientoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'planestratamiento/:id', component: FormplanestratamientoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/plan/:id', component: FormrefinamientoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/ver/:id', component: VerformrefinamientoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/:id/cobro', component: FormcobrorefinamientoComponent, canActivate: [AuthGuardServiceService] }, // paso 2
      { path: 'refinamientos/:id', component: FormmotivoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/comprobante-inicial/:id', component: VercomprobanteinicialComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/enviarresultados/:id', component: EnviarresultadosrefComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/verresultados/:id', component: RecibirresultadosrefComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/paso4/:id', component: PagorefinamientofinalComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/comprobante-tratamiento/:id', component: VerpagorefinamientoComponent, canActivate: [AuthGuardServiceService] },
      { path: 'refinamientos/archivos/:id', component: VerarchivorefComponent, canActivate: [AuthGuardServiceService] },


    ]
  },

  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
    ]
  },
  {
    path: '',
    component: OrtoLayoutComponent,
    children: [
      { path: 'ortodoncistas', component: DoctoresPublicComponent },
    ]
  },
  {
    path: '',
    component: PacienteLayoutComponent,
    children: [
      { path: 'pacientes', component: PacientesPublicComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
