import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { Caso, CasoSingleResponse, CasoResponse } from '../models/Caso';

@Injectable({
  providedIn: 'root'
})
export class CasosapiService extends BaseService {
  getCasosByDoctor(idDoctor: number) {
    return this.getBaseRequest<CasoResponse>('api/doctores/' + idDoctor + '/casos')
      .pipe(map(data => data.data));
  }
  aprobarcasostep3(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/aprobarcasostep3', caso)
      .pipe(map(data => data.data));
  }
  aprobarcasostep4(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/aprobarcasostep4', {})
      .pipe(map(data => data.data));
  }
  entregarCaso(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/entregarcaso', {})
      .pipe(map(data => data.data));
  }
  
  solicitarAprobacionStep3(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/solicitaraprobacionstep3', caso)
      .pipe(map(data => data.data));
  }

  createCaso(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos', caso)
      .pipe(map(data => data.data));
  }
  aprobarcasostep1(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/aprobarcasostep1', {})
      .pipe(map(data => data.data));
  }
  rechazarcasostep1(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/rechazarcasostep1', caso)
      .pipe(map(data => data.data));
  }
  aprobarcasostep2(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/aprobarcasostep2', {})
      .pipe(map(data => data.data));
  }
  rechazarComprobanteStep2(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/rechazarcomprobantestep2', caso)
      .pipe(map(data => data.data));
  }
  rechazarComprobanteStep4(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/rechazarcomprobantestep4', caso)
      .pipe(map(data => data.data));
  }
  comprobanteInicialEnviado(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/comprobanteenviadostep2', {})
      .pipe(map(data => data.data));
  }
  comprobanteTratamientoEnviado(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/comprobanteenviadostep4', caso)
      .pipe(map(data => data.data));
  }
  resultadosEnviados(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/resultadosenviados', caso)
      .pipe(map(data => data.data));
  }
  doctorRechazaCaso(caso: Caso) {
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/doctorRechazaPago', caso)
      .pipe(map(data => data.data));
  }
  doctorRechazaCasoEntregado(caso: Caso){
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/doctorrechazacasoentregado', caso)
      .pipe(map(data => data.data));
  }
  doctorApruebaCasoEntregado(caso: Caso){
    return this.postBaseRequest<CasoSingleResponse>('api/casos/' + caso.id + '/doctorapruebacaso', caso)
      .pipe(map(data => data.data));
  }
  updateCaso(caso: Caso) {
    return this.putBaseRequest<CasoSingleResponse>('api/casos/' + caso.id, caso)
      .pipe(map(data => data.data));
  }
  deleteCaso(id) {
    return this.deleteBaseRequest<CasoSingleResponse>('api/casos/' + id)
      .pipe(map(data => data.res));
  }

  getListacasos() {
    return this.getBaseRequest<CasoResponse>('api/casos')
      .pipe(map(data => data.data));
  }
  getCaso(id: string) {
    return this.getBaseRequest<CasoSingleResponse>('api/casos/' + id)
      .pipe(map(data => data.data));
  }


}
