import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-como-funciona',
  templateUrl: './como-funciona.component.html',
  styleUrls: ['./como-funciona.component.scss']
})
export class ComoFuncionaComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  constructor() { }

  ngOnInit() {
  }

}
