import { Component, OnInit } from '@angular/core';
import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-doctores-public',
  templateUrl: './doctores-public.component.html',
  styleUrls: ['./doctores-public.component.scss']
})
export class DoctoresPublicComponent implements OnInit {

  combinamos: string;

  constructor(private cApi: ContenidosapiService, private actRoute: ActivatedRoute) {
    actRoute.params.subscribe(data => {
      this.getContenidos();
    });
  }
  ngOnInit() {
  }
  getContenidos() {
    this.cApi.getContenido('COMBINAMOS_ESTETICA').subscribe(data => {
      if (data) {
        this.combinamos = data.value;
      }
    });
  }

}
