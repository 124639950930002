import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { Store } from '@ngrx/store';
import { Refinamiento, EstadoRefinamiento } from '../../../models/Refinamiento';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { ArchivorefinamientoapiService } from '../../../services/archivorefinamientoapi.service';

@Component({
  selector: 'app-formcobrorefinamiento',
  templateUrl: './formcobrorefinamiento.component.html',
  styleUrls: ['./formcobrorefinamiento.component.scss']
})
export class FormcobrorefinamientoComponent implements OnInit {
  refinamiento: Refinamiento;
  error: boolean;
  comprobante: any;
  comprobanteData: any;
  urlComprobante: any;


  constructor(
    private actRoute: ActivatedRoute,
    private apiArchivo: ArchivorefinamientoapiService,
    private api: RefinamientosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getRefinamiento(data.id);
      }
    });
  }

  ngOnInit() {
  }
  seleccionarComprobante(e) {
    this.comprobanteData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlComprobante = reader.result;
      }
    }
  }
  getRefinamiento(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo refinamiento..." }));

    this.api.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.error = false;
      } else {
        this.redirectToList();
      }
      console.log(data);

      this.storeLoading.dispatch(hideLoading());
    }, (error) => {
      this.error = true;
      this.redirectToList();
      this.storeLoading.dispatch(hideLoading())
    });
  }
  async enviarDatos() {

    // console.log(this.caso);
    this.refinamiento.status = EstadoRefinamiento.PAGO_INICIAL_ENVIADO;
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Comprobante..." }));
    this.api.comprobanteInicialEnviado(this.refinamiento).subscribe(refinamiento => {
      this.refinamiento = refinamiento;
      this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo imagen..." }));
      this.enviarImagen();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });

  }
  async enviarImagen() {
    if (this.comprobanteData) {
      const myFormData = new FormData();
      myFormData.append('file', this.comprobanteData)
      myFormData.append('tipoArchivo', TipoArchivo.FOTO_COMPROBANTE.toString());
      myFormData.append('step', EstadoRefinamiento.PAGO_INICIAL_ENVIADO.toString());
      myFormData.append('identificador', 'comprobante-inicial');
      // console.log(this.comprobanteData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.refinamiento.id).toPromise();

      if (data) {
        console.log('archivo enviado correctamente');
      }
    }
    this.storeLoading.dispatch(hideLoading());
    this.redirectToList();
  }
  redirectToList() {
    if (this.refinamiento) {
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    } else {
      this.router.navigateByUrl('/casos/');
    }
  }

}
