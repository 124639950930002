import { Action, createReducer, on } from '@ngrx/store';
import { gotPermissions, loggedIn, logout, setUserData } from '../actions/user.actions';
export interface UserState {
    access_token: any,
    permissions: any[],
    userData: any;
};
export const initialState: UserState = {
    access_token: undefined,
    permissions: [],
    userData: undefined
};
const _userReducer = createReducer(initialState,
    on(loggedIn, (state: UserState, { access_token }) => {
        // let changedState = Object.assign({}, state);
        // changedState.access_token = access_token;
        // console.log('loggedIn', changedState);
        // return changedState;

        return { ...state, access_token };
    }),
    on(setUserData, (state: UserState, { userData }) => {
        // let changedState = Object.assign({}, state);
        // changedState.userData = userData;

        sessionStorage.setItem('userData', JSON.stringify(userData));
        // console.log('setUserData', changedState);

        // return changedState;
        return { ...state, userData };
    }),
    on(logout, (state: UserState) => {
        // let changedState = Object.assign({}, state);
        // changedState.access_token = undefined;
        // changedState.permissions = [];
        // changedState.userData = undefined;
        // return changedState;
        return { ...state, userData: undefined, permissions: [], access_token: undefined };
    }),
    on(gotPermissions, (state: UserState, { permissions }) => {
        // const changedState = {
        //     ...state,
        //     permissions: state.permissions
        // }
        return { ...state, permissions };
    })
);
export function UserReducer(state: UserState | undefined, action: Action) {
    return _userReducer(state, action);
}
