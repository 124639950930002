import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';


export class BaseService {
    constructor(protected http: HttpClient, private token: TokenService) { }
    protected getBaseRequest<E>(query: string, authorization = true) {
        const url = `${environment.baseUrl}/${query}`;

        if (authorization) {
            const headers = this.getHeader();
            return this.http.get<E>(url, { headers });
        }
        return this.http.get<E>(url);


    }
    private getHeader() {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.token.get()
        });
        return headers;
    }
    protected postFileRequest<E>(query: string, body: any, authorization = true) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        const url = `${environment.baseUrl}/${query}`;
        return this.http.post<E>(url, body, { headers });
    }
    protected postBaseRequest<E>(query: string, body: any, authorization = true) {
        const url = `${environment.baseUrl}/${query}`;
        if (authorization) {
            const headers = this.getHeader();
            return this.http.post<E>(url, body, { headers });
        }
        return this.http.post<E>(url, body);
    }
    protected putBaseRequest<E>(query: string, body: any, authorization = true) {
        const url = `${environment.baseUrl}/${query}`;
        if (authorization) {
            const headers = this.getHeader();
            return this.http.put<E>(url, body, { headers });
        }
        return this.http.put<E>(url, body);
    }
    protected deleteBaseRequest<E>(query: string, authorization = true) {
        const url = `${environment.baseUrl}/${query}`;
        if (authorization) {
            const headers = this.getHeader();
            return this.http.delete<E>(url, { headers });
        }
        return this.http.delete<E>(url);
    }
}
