import { Component, OnInit } from '@angular/core';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserState } from '../../../redux/reducers/user.reducer';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Observable } from 'rxjs';
import { Refinamiento, EstadoRefinamiento } from '../../../models/Refinamiento';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BaseArchivo } from '../../../models/Archivocaso';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { userHasRole } from '../../../models/User';

@Component({
  selector: 'app-verrefinamiento',
  templateUrl: './verrefinamiento.component.html',
  styleUrls: ['./verrefinamiento.component.scss']
})
export class VerrefinamientoComponent implements OnInit {

  isAdmin = false;
  isDoctor = false;
  public Editor = ClassicEditor;

  archivos: BaseArchivo[] = [];
  
  refinamiento: Refinamiento = {
    id: 0,
    motivoEnvio: "",
    motivoOtroExplique: "",
    nroAlineacionSuperior: "", nroAlineacionInferior: "", caso_id: 0, status: EstadoRefinamiento.PAGO_FINAL_REFINAMIENTO_COMPROBADO,
    pregunta1Arcos: "",
    pregunta2RestriccionDental: '',
    pregunta2dientes: "",
    pregunta3Aditamentos: '',
    pregunta3dientes: "",
    pregunta4aMantenerI: '',
    pregunta4aMantenerD: '',
    pregunta4bMejorarRelacionI: '',
    pregunta4bMejorarRelacionD: '',
    pregunta4cCorreccionI: '',
    pregunta4cCorreccionD: '',
    pregunta4dOpcionMovimiento: "",
    pregunta4daDesgaste: '',
    pregunta4dbSimulacion: '',
    pregunta4dcDistalizacion: '',
    pregunta4deCortes: '',
    pregunta4deElasticoS: '',
    pregunta4deElasticoI: '',
    pregunta4deElasticoV: '',
    pregunta4deElasticoL: '',
    pregunta4deBotonS: '',
    pregunta4deBotonI: '',
    pregunta4deBotonV: '',
    pregunta4deBotonL: '',
    pregunta4dObservacion: '',
    pregunta5aOverjetMostrar: '',
    pregunta5bOverjetMejorar: '',
    pregunta6: "",
    pregunta6b: "",
    pregunta6c: "",
    // pregunta6aOverbiteMostrar: "",
    pregunta6caInstruirSolamenteSup: "",
    pregunta6caInstruirSolamenteInf: "",
    pregunta6baExtruirSolamenteInf: "",
    pregunta6baExtruirSolamenteSup: "",
    pregunta6bbExtruireInstruirInf: "",
    pregunta6bbExtruireInstruirSup: "",
    // pregunta6caInstruirSolamente: "",
    // pregunta6cbInstruiryExtruir: "",
    pregunta6cbInstruiryExtruirSup: "",
    pregunta6cbInstruiryExtruirInf: "",
    pregunta6dOtroObs: '',
    pregunta7: "",
    pregunta7bModificarLineaMediaSup: "",
    pregunta7bModificarLineaMediaInf: "",
    pregunta7bbObservacion: '',
    pregunta8: '',
    pregunta8bOtro: '',
    pregunta8baApinamiento: "",
    pregunta8bbVestibularizar: "",
    pregunta8bcDipAnterior: "",
    pregunta8bdDipPosteriorDer: "",
    pregunta8beDipPosteriorIzq: "",
    pregunta8caApinamiento: "",
    pregunta8cbVestibularizar: "",
    pregunta8ccDipAnterior: "",
    pregunta8cdDipPosteriorDer: "",
    pregunta8ceDipPosteriorIzq: "",
    pregunta8dExtraccion: '',
    pregunta8ddientes: "",
    pregunta9ObservacionesComplementarias: '',
    created_at: '',
    updated_at: ''
  };
  error: boolean;
  userData$: Observable<any>;
  constructor(
    private actRoute: ActivatedRoute,
    private apiRefinamiento: RefinamientosapiService,
    private store: Store<{ user: UserState }>,
    private storeLoading: Store<LoadingState>
  ) {
    
    this.userData$ = this.store.select(store => store.user.userData);
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getRefinamiento(data.id);
      } else {
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.PlanTratamiento.fechaNacimiento);
      }
    });
  }


  getRefinamiento(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo refinamiento..." }));

    this.apiRefinamiento.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.archivos = data.archivos;

        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  ngOnInit() {
    this.userData$.subscribe(data => {
      this.checkAdminPermission(data);
    })
  }
 
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
    this.isDoctor = userHasRole('Doctor', userData);
  }

}
