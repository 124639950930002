import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Diente } from 'src/app/models/Dientes';

@Component({
  selector: 'app-diente',
  templateUrl: './diente.component.html',
  styleUrls: ['./diente.component.scss']
})
export class DienteComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() diente: Diente;
  @Output() dienteSelected = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }
  selected(){
    this.dienteSelected.emit({diente: this.diente});
    
  }
  unselected(){
    this.dienteSelected.emit({diente: this.diente});
  }
  changeDiente(e){
    if(this.diente.selected){
      this.selected();
    }else{
      this.unselected();
    }
  }
}
