import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { Caso, EstadoCaso } from 'src/app/models/Caso';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-verpaso2',
  templateUrl: './verpaso2.component.html',
  styleUrls: ['./verpaso2.component.scss']
})
export class Verpaso2Component implements OnInit {

  error: boolean;
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  modalRechazar: any;
  constructor(
    private actRoute: ActivatedRoute,
    private api: CasosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getImageUrl(file: string) {
    return environment.baseUrl + '/files/archivoscaso/' + this.caso.id + '/' + file + '.jpg';
  }
  aprobarComprobante() {
    this.api.aprobarcasostep2(this.caso).subscribe(data => {
      // console.log(data);
      this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazarComprobante() {
    this.api.rechazarComprobanteStep2(this.caso).subscribe(data => {
      // console.log(data);
      this.modalRechazar.close();
      this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }

}
