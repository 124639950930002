import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { userHasRole } from 'src/app/models/User';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { LanguageApp } from 'src/app/utilities/LanguageApp';
import { environment } from 'src/environments/environment';
import { Caso, EstadoCaso, EstadoCasoText } from '../../../models/Caso';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { getHumanDateTimeFromString } from '../../../utilities/DateUtilities';
import { Refinamiento } from '../../../models/Refinamiento';

@Component({
  selector: 'app-verpaciente',
  templateUrl: './verpaciente.component.html',
  styleUrls: ['./verpaciente.component.scss']
})
export class VerpacienteComponent implements OnInit {
  estadosCaso = EstadoCaso;
  isAdmin = false;
  isDoctor = false;
  caso: Caso;
  userData$: Observable<any>;
  dtOptions: any;
  refinamientos: Refinamiento[] = [];
  isVerPlanVisible: boolean = false;
  isModificarTratamientoVisible: boolean = false;
  constructor(
    private api: CasosapiService,
    private apiRefinamientos: RefinamientosapiService,
    private store: Store<{ user: UserState }>,
    private storeLoading: Store<LoadingState>,
    private router: Router,
    private actRoute: ActivatedRoute,

  ) {
    this.userData$ = this.store.select(store => store.user.userData);
    this.userData$.subscribe(data => {
      // console.log('userData listacasos', data)
      if (!data) {
        return;
      }
      this.checkAdminPermission(data);
    }, (error) => {
      console.log(error);
    });
    this.actRoute.params.subscribe(params => {
      this.getCaso(params.id);
      this.getRefinamientos(params.id);
    });
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo paciente..." }));
    this.api.getCaso(id).subscribe(data => {
      this.caso = data;
      this.validatePermisos();
      this.storeLoading.dispatch(hideLoading());
    });
  }
  getRefinamientos(idCaso: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo refinamientos..." }));
    this.apiRefinamientos.getByCaso(idCaso).subscribe(data => {
      this.refinamientos = data;
      this.storeLoading.dispatch(hideLoading());
    }
      , (error) => {
        console.log(error);
      }
    );
  }

  ngOnInit() {
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'colvis',
        'copy',
        'print',
        'excel',
        'csv'
      ],
      language: LanguageApp.spanish_datatables,
      order: []
    };

  }
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
    this.isDoctor = userHasRole('Doctor', userData);
    // console.log(this.isAdmin);
  }
  getZipUrl(id) {
    return environment.baseUrl + '/api/casos/' + id + '/download';
  }
  tieneSolicitudesAbiertas(caso: Caso) {
    if (!caso.solicitudes) {
      return false;
    }
    if (caso.solicitudes.length == 0) {
      return false;
    }
    let solicitudesFiltradas = caso.solicitudes.filter(sol => !sol.resuelto);
    if (solicitudesFiltradas.length == 0) {
      return false;
    }
    return true;
  }
  eliminarCaso(id) {
    // console.log(id);
    this.storeLoading.dispatch(showLoading({ loadingText: "Eliminando caso..." }));
    this.api.deleteCaso(id).subscribe(res => {
      this.storeLoading.dispatch(hideLoading());
      if (res !== 'success') {
        console.log(res);
      } else {
        this.router.navigateByUrl('/casos');
      }
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  entregarCaso(caso: Caso) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Entregando caso..." }));
    this.api.entregarCaso(caso).subscribe(data => {
      this.storeLoading.dispatch(hideLoading());
      // console.log(data);
      this.getCaso(caso.id.toString());
    }, (error) => {
      console.log(error);
    });
  }
  getEstado() {
    if (this.tieneSolicitudesAbiertas(this.caso)) {
      return "Información Solicitada";
    }
    return EstadoCasoText[this.caso.status];
  }
  getGoogleMapsLink() {
    return "http://www.google.com/maps/place/" + this.caso.latitudEntrega + "," + this.caso.longitudEntrega;
  }

  reloadRefinamientos() {
    this.getRefinamientos(this.caso.id.toString());
  }
  validatePermisos() {
    this.isVerPlanVisible = this.caso.status >= EstadoCaso.PAGO_INICIAL_VERIFICADO || this.caso.status <= EstadoCaso.PAGO_INICIAL_ERRONEO;
    this.isModificarTratamientoVisible =
      (this.caso.status >= EstadoCaso.TRATAMIENTO_APROBADO_PAGO_SOLICITADO
        || this.caso.status <= EstadoCaso.PAGO_INICIAL_ERRONEO) &&
      this.caso.status != EstadoCaso.CASO_ENTREGADO_Y_APROBADO;
  }
}
