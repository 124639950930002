import { Component, OnInit } from '@angular/core';
import { Contenido } from 'src/app/models/Contenido';
import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';

@Component({
  selector: 'app-listafotos',
  templateUrl: './listafotos.component.html',
  styleUrls: ['./listafotos.component.scss']
})
export class ListafotosComponent implements OnInit {

  contenidos: Contenido[] = [];
  constructor(private api: ContenidosapiService, private actRoute: ActivatedRoute,
    private storeLoading: Store<LoadingState>

    ) {
    // constructor(){
    this.actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarContenidos();
      }

    });


  }
  recargarContenidos() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo contenidos..." }));

    this.api.getListaContenidosTipo(1).subscribe(listaContenidos => {
      this.contenidos = listaContenidos;
      this.storeLoading.dispatch(hideLoading());
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
  }
  getImageUrl(contenido) {
    return environment.baseUrl + '/img/contenidos/' + contenido.id + '.jpg?rand=' + Math.random();
  }
}
