import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { Caso, EstadoCaso } from 'src/app/models/Caso';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { ArchivoCasoapiService } from 'src/app/services/archivocasoapi.service';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { isFileImage } from 'src/app/utilities/FileUtilities';

@Component({
  selector: 'app-enviarresultados',
  templateUrl: './enviarresultados.component.html',
  styleUrls: ['./enviarresultados.component.scss']
})
export class EnviarresultadosComponent implements OnInit {

  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.TRATAMIENTO_APROBADO_PAGO_SOLICITADO };
  error: boolean;
  comprobante: any;
  pdfData: any;
  urlComprobante: any;


  constructor(
    private actRoute: ActivatedRoute,
    private apiArchivo: ArchivoCasoapiService,
    private api: CasosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  seleccionarPdf(e) {
    this.pdfData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlComprobante = reader.result;
      }
    }
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo paciente" }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarDatos() {

    // console.log(this.caso);
    this.caso.status = EstadoCaso.PAGO_TRATAMIENTO_REALIZADO;
    this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo resultados..." }));
    this.api.resultadosEnviados(this.caso).subscribe(caso => {
      this.caso = caso;
      this.storeLoading.dispatch(hideLoading());
      this.storeLoading.dispatch(showLoading({ loadingText: "Cargando PDF..." }));
      this.enviarPdf();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });

  }
  async enviarPdf() {
    if (this.pdfData) {
      const myFormData = new FormData();
      myFormData.append('file', this.pdfData)
      myFormData.append('tipoArchivo', TipoArchivo.PDF.toString());
      myFormData.append('step', EstadoCaso.CASO_ENTREGADO.toString());
      myFormData.append('identificador', 'pdf-caso');
      // console.log(this.pdfData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.caso.id).toPromise();
      if (data) {
        // console.log('archivo enviado correctamente');
        this.storeLoading.dispatch(hideLoading());
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
    this.redirectToList();
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
  }



}
