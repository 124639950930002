import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getFormattedDateTimeFromString } from '../../../utilities/DateUtilities';
import { Genero } from '../../../models/Caso';
import { Refinamiento, EstadoRefinamiento } from '../../../models/Refinamiento';
import { ArchivoRefinamiento } from '../../../models/Archivorefinamiento';
import { ActivatedRoute, Router } from '@angular/router';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';

@Component({
  selector: 'app-verarchivoref',
  templateUrl: './verarchivoref.component.html',
  styleUrls: ['./verarchivoref.component.scss']
})
export class VerarchivorefComponent implements OnInit {
  error: boolean;
  generos = Genero;
  refinamiento: Refinamiento = {
    id: 0, status: EstadoRefinamiento.PAGO_FINAL_REFINAMIENTO_REALIZADO, latitudEntrega: '-17.783202', longitudEntrega: '-63.181687',
    motivoEnvio: '',
    motivoOtroExplique: '',
    nroAlineacionSuperior: '',
    nroAlineacionInferior: '',
    caso_id: 0
  };
  modalRechazar: any;
  estadosRefinamiento = EstadoRefinamiento;
  archivos: ArchivoRefinamiento[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private api: RefinamientosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.archivos = this.refinamiento.archivos;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getImageUrl(file: string) {
    return environment.baseUrl + '/files/archivosrefinamiento/' + this.refinamiento.id + '/' + file + '.jpg';
  }

  getFormattedDate(date: string) {
    return getFormattedDateTimeFromString(date);
  }
  getFileUrl(file, extension) {
    return environment.baseUrl + '/files/archivosrefinamiento/' + this.refinamiento.id + '/' + file + '.' + extension;
  }
  archivoExiste(identificador: string) {
    return this.archivos.filter(archivo => archivo.identificador == identificador).length > 0;
  }

}
