import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { Observable } from 'rxjs';
import { showLoading } from '../../redux/actions/loading.actions';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  loading: Observable<boolean>;
  loadingText: Observable<string>;
  showLoading: boolean = false;
  text: string;
  constructor(
    private store: Store<{ load: LoadingState }>
  ) {
    this.loading = this.store.select(store => store.load.loading);
    this.loadingText = this.store.select(store => store.load.loadingText);
  }

  ngOnInit() {
    this.loading
    .subscribe(data => {
      // console.log('loading changed', data)
      this.showLoading = data;
    }, (error) => {
      console.log(error);
    })
  }

}
