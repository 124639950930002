import { Component, OnInit, ViewChild } from '@angular/core';
import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-enviar-caso',
  templateUrl: './enviar-caso.component.html',
  styleUrls: ['./enviar-caso.component.scss']
})
export class EnviarCasoComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  textoRegistrese: string;
  constructor(private cApi: ContenidosapiService, private actRoute: ActivatedRoute) {
    actRoute.params.subscribe(data => {
      this.getContenidos();
    });
  }

  ngOnInit() {
  }
  getContenidos() {
    this.cApi.getContenido('ENVIE_SU_CASO').subscribe(data => {
      if (data) {
        this.textoRegistrese = data.value;
      }
    });
  }
}
