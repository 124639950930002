import { Component, OnInit } from '@angular/core';
import { Doctor } from 'src/app/models/Doctor';
import { DoctoresapiService } from 'src/app/services/doctoresapi.service';
import { ActivatedRoute } from '@angular/router';
import { LanguageApp } from 'src/app/utilities/LanguageApp';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-listadoctores',
  templateUrl: './listadoctores.component.html',
  styleUrls: ['./listadoctores.component.scss']
})
export class ListadoctoresComponent implements OnInit {


  doctores: Doctor[] = [];
  dtOptions: any;
  constructor(private api: DoctoresapiService, private actRoute: ActivatedRoute,
    private storeLoading: Store<LoadingState>
    ) {
    // constructor(){
    this.actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarDoctores();
      }

    });


  }
  recargarDoctores() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo lista de doctores..." }));

    this.api.getListadoctores().subscribe(listaDoctores => {
      this.doctores = listaDoctores;
      this.storeLoading.dispatch(hideLoading());
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'colvis',
        'copy',
        'print',
        'excel',
        'csv'
      ],
      language: LanguageApp.spanish_datatables
    };
  }
  eliminarDoctor(id) {
    // console.log(id);
    this.storeLoading.dispatch(showLoading({ loadingText: "Eliminando doctor..." }));
    this.api.deleteDoctor(id).subscribe(res => {
      this.storeLoading.dispatch(hideLoading());
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarDoctores();
      }
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }

}
