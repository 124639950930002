import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Caso } from 'src/app/models/Caso';
import { SolicitudinformacionService } from '../../../services/solicitudinformacion.service';
import { SolicitudInformacion } from '../../../models/SolicitudInformacion';

@Component({
  selector: 'app-solicitarinformacion',
  templateUrl: './solicitarinformacion.component.html',
  styleUrls: ['./solicitarinformacion.component.scss']
})
export class SolicitarinformacionComponent implements OnInit {
  @Input() type: string;
  @Input() caso: Caso;
  currentModal: NgbModalRef;
  solicitud: SolicitudInformacion = {
    caso_id: 0, id: 0, texto_solicitud: '', texto_respuesta: '', step: 0, incluye_archivos: false, resuelto: false
  };


  constructor(
    private modalService: NgbModal,
    private api: SolicitudinformacionService
  ) { }

  ngOnInit() {
  }
  solicitarInformacion(content) {
    this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.currentModal.result.then((result) => {

    }, (reason) => {

    });
  }
  enviarSolicitud() {
    this.solicitud.caso_id = this.caso.id;
    this.solicitud.step = this.caso.status;
    this.api.createSolicitudInformacion(this.solicitud).subscribe(data => {
      this.currentModal.close();
    }, error => {
      alert('Hubo un error al enviar los datos, por favor intente nuevamente');
      console.log(error);
    })
  }
  getSeparation() {
    return this.type == 'block' ? ' ' : '';
  }
}
