import { Component, OnInit } from '@angular/core';
import { hideLoading, showLoading } from '../../../redux/actions/loading.actions';
import { EstadoRefinamiento, Refinamiento } from '../../../models/Refinamiento';
import { ActivatedRoute, Router } from '@angular/router';
import { ArchivorefinamientoapiService } from '../../../services/archivorefinamientoapi.service';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { Store } from '@ngrx/store';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { isFileImage } from '../../../utilities/FileUtilities';
import { TipoArchivo } from '../../../models/Archivocaso';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pagorefinamientofinal',
  templateUrl: './pagorefinamientofinal.component.html',
  styleUrls: ['./pagorefinamientofinal.component.scss']
})
export class PagorefinamientofinalComponent implements OnInit {

  refinamiento: Refinamiento;
  error: boolean;
  comprobante: any;
  comprobanteData: any;
  urlComprobante: any;
  marker: any = {
    position: {
      lat: -17.783202,
      lng: -63.181687,
    },
    title: 'Ubicación actual ',
    options: { animation: google.maps.Animation.BOUNCE },
  }

    ;
  // Configuración de Google Maps 
  center = {
    lat: -17.783202,
    lng: -63.181687,
  };
  zoom = 15;
  display?: google.maps.LatLngLiteral;

  constructor(
    private actRoute: ActivatedRoute,
    private apiArchivo: ArchivorefinamientoapiService,
    private api: RefinamientosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getRefinamiento(data.id);
      }
    });
  }

  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.changeMarkerLocation(this.center.lat, this.center.lng);
    })
  }
  seleccionarComprobante(e) {
    this.comprobanteData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlComprobante = reader.result;
      }
    }
  }
  getRefinamiento(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del refinamiento..." }));

    this.api.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarDatos() {

    // console.log(this.caso);
    this.refinamiento.status = EstadoRefinamiento.PAGO_FINAL_REFINAMIENTO_REALIZADO;
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Comprobante..." }));
    this.api.comprobanteTratamientoEnviado(this.refinamiento).subscribe(caso => {
      this.refinamiento = caso;
      this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo imagen..." }));
      this.enviarImagen();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });

  }
  async enviarImagen() {
    if (this.comprobanteData) {
      const myFormData = new FormData();
      myFormData.append('file', this.comprobanteData)
      myFormData.append('tipoArchivo', TipoArchivo.FOTO_COMPROBANTE.toString());
      myFormData.append('step', EstadoRefinamiento.PAGO_FINAL_REFINAMIENTO_REALIZADO.toString());
      myFormData.append('identificador', 'comprobante-tratamiento');
      // console.log(this.comprobanteData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.refinamiento.id).toPromise()
      if (data) {
        // console.log('archivo enviado correctamente');
        this.storeLoading.dispatch(hideLoading());
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();

    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
    this.redirectToList();
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
  }
  doctorRechazaPresupuesto() {
    let confirmation = confirm('¿Está seguro que desea rechazar el presupuesto actual?\nSi rechaza el presupuesto su caso será descartado');
    if (confirmation) {
      this.doctorRechazaCaso();
    }
  }
  doctorRechazaCaso() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Rechazando presupuesto..." }));

    // console.log(this.caso);
    this.refinamiento.status = EstadoRefinamiento.PAGO_RECHAZADO_POR_DOCTOR;
    this.api.doctorRechazaRefinamiento(this.refinamiento).subscribe(caso => {
      this.refinamiento = caso;
      this.storeLoading.dispatch(hideLoading());
      this.redirectToList();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  getGmapsUrl() {
    return "https://maps.googleapis.com/maps/api/js?key=" + environment.mapsApiKey
  }
  clickMapa(event: google.maps.MouseEvent) {
    // console.log(event);
    // console.log(event.latLng);
    // console.log(event.latLng.lat());
    // console.log(event.latLng.lng());
    this.changeMarkerLocation(event.latLng.lat(), event.latLng.lng());
  }
  changeMarkerLocation(lat, lng) {
    this.marker = {
      position: {
        lat: lat,
        lng: lng,
      },
      title: 'Ubicación actual ',
      options: { animation: google.maps.Animation.BOUNCE },
    };
    this.refinamiento.latitudEntrega = lat;
    this.refinamiento.longitudEntrega = lng;
  }

}
