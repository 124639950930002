import * as moment from 'moment';

export function getFormattedDateTimeFromString(theDate: string) {
    if (theDate == undefined) {
        return '';
    }
    // console.log(theDate);
    // console.log(moment(theDate));
    return moment(theDate).format('YYYY-MM-DD HH:mm');
}
export function getHumanDateTimeFromString(theDate: string) {
    if (theDate == undefined) {
        return '';
    }
    // console.log(theDate);
    // console.log(moment(theDate));
    return moment(theDate).format('DD/MM/YYYY HH:mm');
}