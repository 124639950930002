import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { Caso, EstadoCaso } from 'src/app/models/Caso';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { ArchivoCasoapiService } from 'src/app/services/archivocasoapi.service';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paso4',
  templateUrl: './paso4.component.html',
  styleUrls: ['./paso4.component.scss']
})
export class Paso4Component implements OnInit {

  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.TRATAMIENTO_APROBADO_PAGO_SOLICITADO };
  error: boolean;
  comprobante: any;
  comprobanteData: any;
  urlComprobante: any;
  marker: any = {
    position: {
      lat: -17.783202,
      lng: -63.181687,
    },
    title: 'Ubicación actual ',
    options: { animation: google.maps.Animation.BOUNCE },
  }

    ;
  // Configuración de Google Maps 
  center = {
    lat: -17.783202,
    lng: -63.181687,
  };
  zoom = 15;
  display?: google.maps.LatLngLiteral;

  constructor(
    private actRoute: ActivatedRoute,
    private apiArchivo: ArchivoCasoapiService,
    private api: CasosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
  ) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.changeMarkerLocation(this.center.lat, this.center.lng);
    })
  }
  seleccionarComprobante(e) {
    this.comprobanteData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlComprobante = reader.result;
      }
    }
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarDatos() {

    // console.log(this.caso);
    this.caso.status = EstadoCaso.PAGO_TRATAMIENTO_REALIZADO;
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Comprobante..." }));
    this.api.comprobanteTratamientoEnviado(this.caso).subscribe(caso => {
      this.caso = caso;
      this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo imagen..." }));
      this.enviarImagen();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });

  }
  async enviarImagen() {
    if (this.comprobanteData) {
      const myFormData = new FormData();
      myFormData.append('file', this.comprobanteData)
      myFormData.append('tipoArchivo', TipoArchivo.FOTO_COMPROBANTE.toString());
      myFormData.append('step', EstadoCaso.PAGO_TRATAMIENTO_REALIZADO.toString());
      myFormData.append('identificador', 'comprobante-tratamiento');
      // console.log(this.comprobanteData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.caso.id).toPromise()
      if (data) {
        // console.log('archivo enviado correctamente');
        this.storeLoading.dispatch(hideLoading());
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();

    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
    this.redirectToList();
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
  }
  doctorRechazaPresupuesto() {
    let confirmation = confirm('¿Está seguro que desea rechazar el presupuesto actual?\nSi rechaza el presupuesto su caso será descartado');
    if (confirmation) {
      this.doctorRechazaCaso();
    }
  }
  doctorRechazaCaso() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Rechazando presupuesto..." }));

    // console.log(this.caso);
    this.caso.status = EstadoCaso.PAGO_RECHAZADO_POR_DOCTOR;
    this.api.doctorRechazaCaso(this.caso).subscribe(caso => {
      this.caso = caso;
      this.storeLoading.dispatch(hideLoading());
      this.redirectToList();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  getGmapsUrl() {
    return "https://maps.googleapis.com/maps/api/js?key=" + environment.mapsApiKey
  }
  clickMapa(event: google.maps.MouseEvent) {
    // console.log(event);
    // console.log(event.latLng);
    // console.log(event.latLng.lat());
    // console.log(event.latLng.lng());
    this.changeMarkerLocation(event.latLng.lat(), event.latLng.lng());
  }
  changeMarkerLocation(lat, lng) {
    this.marker = {
      position: {
        lat: lat,
        lng: lng,
      },
      title: 'Ubicación actual ',
      options: { animation: google.maps.Animation.BOUNCE },
    };
    this.caso.latitudEntrega = lat;
    this.caso.longitudEntrega = lng;
  }
}
