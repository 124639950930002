import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoctoresapiService } from 'src/app/services/doctoresapi.service';
import { Doctor } from 'src/app/models/Doctor';

@Component({
  selector: 'app-encontrar-doctor',
  templateUrl: './encontrar-doctor.component.html',
  styleUrls: ['./encontrar-doctor.component.scss']
})
export class EncontrarDoctorComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  doctores: Doctor[] = [];
  loading: boolean;
  constructor(private api: DoctoresapiService, private actRoute: ActivatedRoute) { 
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarDoctores();
      }
    });
  }

  ngOnInit() {
  }
  recargarDoctores() {
    this.loading = true;
    this.api.getListadoctores().subscribe(listaDoctores => {
      this.doctores = listaDoctores;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }
}
