
import { Component, OnInit } from '@angular/core';
import { PlanTratamiento } from 'src/app/models/PlanTratamiento';
import { ActivatedRoute, Router } from '@angular/router';
import { EstadoCaso, Caso } from 'src/app/models/Caso';
import { CasosapiService } from 'src/app/services/casosapi.service';

import { Boca, PosicionBoca } from '../../../models/Dientes';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { userHasRole } from 'src/app/models/User';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from '../../../redux/reducers/user.reducer';
import { environment } from 'src/environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { BaseArchivo } from '../../../models/Archivocaso';

@Component({
  selector: 'app-verformtratamiento',
  templateUrl: './verformtratamiento.component.html',
  styleUrls: ['./verformtratamiento.component.scss']
})
export class VerformtratamientoComponent implements OnInit {

  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  isAdmin = false;
  isDoctor = false;
  public Editor = ClassicEditor;

  archivos: BaseArchivo[] = [];
  
  plantratamiento: PlanTratamiento = {
    id: 0,
    pregunta1Arcos: "",
    pregunta2RestriccionDental: '',
    pregunta2dientes: "",
    pregunta3Aditamentos: '',
    pregunta3dientes: "",
    pregunta4aMantenerI: '',
    pregunta4aMantenerD: '',
    pregunta4bMejorarRelacionI: '',
    pregunta4bMejorarRelacionD: '',
    pregunta4cCorreccionI: '',
    pregunta4cCorreccionD: '',
    pregunta4dOpcionMovimiento: "",
    pregunta4daDesgaste: '',
    pregunta4dbSimulacion: '',
    pregunta4dcDistalizacion: '',
    pregunta4deCortes: '',
    pregunta4deElasticoS: '',
    pregunta4deElasticoI: '',
    pregunta4deElasticoV: '',
    pregunta4deElasticoL: '',
    pregunta4deBotonS: '',
    pregunta4deBotonI: '',
    pregunta4deBotonV: '',
    pregunta4deBotonL: '',
    pregunta4dObservacion: '',
    pregunta5aOverjetMostrar: '',
    pregunta5bOverjetMejorar: '',
    pregunta6: "",
    pregunta6b: "",
    pregunta6c: "",
    pregunta6caInstruirSolamenteSup: "",
    pregunta6caInstruirSolamenteInf: "",
    pregunta6baExtruirSolamenteInf: "",
    pregunta6baExtruirSolamenteSup: "",
    pregunta6bbExtruireInstruirInf: "",
    pregunta6bbExtruireInstruirSup: "",
    pregunta6cbInstruiryExtruirSup: "",
    pregunta6cbInstruiryExtruirInf: "",
    pregunta6dOtroObs: '',
    pregunta7: "",
    pregunta7bModificarLineaMediaSup: "",
    pregunta7bModificarLineaMediaInf: "",
    pregunta7bbObservacion: '',
    pregunta8: '',
    pregunta8bOtro: '',
    pregunta8baApinamiento: "",
    pregunta8bbVestibularizar: "",
    pregunta8bcDipAnterior: "",
    pregunta8bdDipPosteriorDer: "",
    pregunta8beDipPosteriorIzq: "",
    pregunta8caApinamiento: "",
    pregunta8cbVestibularizar: "",
    pregunta8ccDipAnterior: "",
    pregunta8cdDipPosteriorDer: "",
    pregunta8ceDipPosteriorIzq: "",
    pregunta8dExtraccion: '',
    pregunta8ddientes: "",
    pregunta9ObservacionesComplementarias: '',
    caso_id: 0,
    created_at: '',
    updated_at: ''
  };
  error: boolean;
  userData$: Observable<any>;
  constructor(
    private actRoute: ActivatedRoute,
    private apiCaso: CasosapiService,
    private store: Store<{ user: UserState }>,
    private storeLoading: Store<LoadingState>
  ) {
    
    this.userData$ = this.store.select(store => store.user.userData);
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCaso(data.id);
      } else {
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.PlanTratamiento.fechaNacimiento);
      }
    });
  }

  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.apiCaso.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        if (this.caso.plantratamiento) {
          this.plantratamiento = this.caso.plantratamiento;
          this.archivos = this.caso.archivos;
        }
        this.error = false;
      } else {
        this.error = true;
      }

      this.storeLoading.dispatch(hideLoading());
    });
  }
  ngOnInit() {
    this.userData$.subscribe(data => {
      this.checkAdminPermission(data);
    })
  }
 
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
    this.isDoctor = userHasRole('Doctor', userData);
  }
}
