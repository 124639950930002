import { Component, OnInit } from '@angular/core';
import { Refinamiento } from 'src/app/models/Refinamiento';
import { ActivatedRoute, Router } from '@angular/router';
import { EstadoRefinamiento } from '../../../models/Refinamiento';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { showLoading } from '../../../redux/actions/loading.actions';
import { Store } from '@ngrx/store';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { hideLoading } from 'src/app/redux/actions/loading.actions';

@Component({
  selector: 'app-formmotivo',
  templateUrl: './formmotivo.component.html',
  styleUrls: ['./formmotivo.component.scss']
})
export class FormmotivoComponent implements OnInit {
  refinamiento: Refinamiento = {
    motivoEnvio: "0",
    motivoOtroExplique: '',
    nroAlineacionSuperior: '',
    nroAlineacionInferior: '',
    id: 0,
    status: EstadoRefinamiento.REFINAMIENTO_SOLICITADO,
    caso_id: 0
  };
  casoId: string;
  constructor(
    private actRoute: ActivatedRoute,
    private api: RefinamientosapiService,
    private storeLoading: Store<LoadingState>,
    private router: Router
  ) {
    this.actRoute.params.subscribe(data => {
      this.casoId = data.id;
    });
  }

  ngOnInit() {

  }
  changePregunta1Obs() {
    this.refinamiento.motivoEnvio = "5";
  }
  changePregunta1(value) {
    if (value == "5") {
      return;
    }
    this.refinamiento.motivoOtroExplique = "";
  }

  guardarDatos() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando datos..." }));
    this.refinamiento.status = EstadoRefinamiento.FORMULARIO_REFINAMIENTO_ENVIADO;
    this.refinamiento.caso_id = parseInt(this.casoId);
    if (this.refinamiento.id === 0) {
      this.api.createRefinamiento(this.refinamiento).subscribe(refinamiento => {
        this.refinamiento = refinamiento;
        this.storeLoading.dispatch(hideLoading());
        sessionStorage.setItem('refinamiento_id', this.refinamiento.id.toString());
        sessionStorage.setItem('caso_id', this.refinamiento.caso_id.toString());
        this.storeLoading.dispatch(hideLoading());
        this.router.navigateByUrl('/refinamientos/' + this.refinamiento.id + '/cobro');
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.api.updateRefinamiento(this.refinamiento).subscribe(refinamiento => {
        this.refinamiento = refinamiento;
        this.storeLoading.dispatch(hideLoading());
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }
  validateNo(e): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true
  }

}
