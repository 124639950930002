import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-paciente-menu',
  templateUrl: './paciente-menu.component.html',
  styleUrls: ['./paciente-menu.component.scss']
})
export class PacienteMenuComponent implements OnInit {
  @Input() comoFunciona: any;
  @Input() diferenciasPaciente: any;
  @Input() encontrarDoctor: any;
  @Input() ventajas: any;
  constructor() { }

  ngOnInit() {
  }

  scroll(el: any) {
    // console.log(el);
    el.rootElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
