import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { ArchivoCasoSingleResponse, ArchivoCaso, ArchivoCasoResponse } from '../models/Archivocaso';

@Injectable({
  providedIn: 'root'
})
export class ArchivoCasoapiService extends BaseService {
  enviarFoto(datos: any, id) {
    return this.postFileRequest<ArchivoCasoSingleResponse>('api/archivoscaso/' + id + '/upload', datos)
      .pipe(map(data => data.data));
  }

  createArchivoCaso(doctor: ArchivoCaso) {
    return this.postBaseRequest<ArchivoCasoSingleResponse>('api/archivoscaso', doctor)
      .pipe(map(data => data.data));
  }
  updateArchivoCaso(doctor: ArchivoCaso) {
    return this.putBaseRequest<ArchivoCasoSingleResponse>('api/archivoscaso/' + doctor.id, doctor)
      .pipe(map(data => data.data));
  }
  deleteArchivoCaso(id) {
    return this.deleteBaseRequest<ArchivoCasoSingleResponse>('api/archivoscaso/' + id)
      .pipe(map(data => data.res));
  }

  getListaArchivoCasos() {
    return this.getBaseRequest<ArchivoCasoResponse>('api/archivoscaso')
      .pipe(map(data => data.data));
  }
  getArchivoCaso(id: string) {
    return this.getBaseRequest<ArchivoCasoSingleResponse>('api/archivoscaso/' + id)
      .pipe(map(data => data.data));
  }
}
