import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Genero, Caso, EstadoCaso } from 'src/app/models/Caso';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { getFormattedDateTimeFromString } from 'src/app/utilities/DateUtilities';
import { environment } from 'src/environments/environment';
import { hideLoading, showLoading } from '../../../redux/actions/loading.actions';
import { Store } from '@ngrx/store';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { BaseArchivo, ArchivoCaso } from '../../../models/Archivocaso';

@Component({
  selector: 'app-verarchivos',
  templateUrl: './verarchivos.component.html',
  styleUrls: ['./verarchivos.component.scss']
})
export class VerarchivosComponent implements OnInit {
  error: boolean;
  generos = Genero;
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  modalRechazar: any;
  estadosCaso = EstadoCaso;
  archivos: ArchivoCaso[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private api: CasosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.archivos = this.caso.archivos;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getImageUrl(file: string) {
    return environment.baseUrl + '/files/archivoscaso/' + this.caso.id + '/' + file + '.jpg';
  }
  aprobarCaso() {
    this.api.aprobarcasostep1(this.caso).subscribe(data => {
      // console.log(data);
      this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }

  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }
  confirmarRechazo() {
    this.api.rechazarcasostep1(this.caso).subscribe(data => {
      this.modalRechazar.close('Save click')
      this.router.navigateByUrl('/casos'+this.caso.id+'verpaciente');
    }, error => { console.log(error) });

  }
  getFormattedDate(date: string) {
    return getFormattedDateTimeFromString(date);
  }
  getFileUrl(file, extension) {
    return environment.baseUrl + '/files/archivoscaso/' + this.caso.id + '/' + file + '.' + extension;
  }
  archivoExiste(identificador: string) {
    return this.archivos.filter(archivo => archivo.identificador == identificador).length > 0;
  }

}
