import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Caso, EstadoCaso } from 'src/app/models/Caso';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { environment } from 'src/environments/environment';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';

@Component({
  selector: 'app-recibirresultados',
  templateUrl: './recibirresultados.component.html',
  styleUrls: ['./recibirresultados.component.scss']
})
export class RecibirresultadosComponent implements OnInit {

  error: boolean;
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.PAGO_TRATAMIENTO_REALIZADO };
  modalRechazar: any;
  modalAprobar: any;
  constructor(
    private actRoute: ActivatedRoute,
    private api: CasosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getFileUrl(file: string) {
    return environment.baseUrl + '/files/archivoscaso/' + this.caso.id + '/' + file + '.pdf';
  }
  aprobarResultados() {
    this.api.doctorApruebaCasoEntregado(this.caso).subscribe(data => {
      // console.log(data);
      this.modalAprobar.close();
      this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazarResultados() {
    this.api.doctorRechazaCasoEntregado(this.caso).subscribe(data => {
      // console.log(data);
      this.modalRechazar.close();
      this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }
  aprobar(content) {
    this.modalAprobar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalAprobar.result.then((result) => { }, (reason) => { });
  }

  getThumbnailUrl(url: string) {
    //https://img.youtube.com/vi/<insert-youtube-video-id-here>/hqdefault.jpg
    // https://www.youtube.com/watch?v=W0_CXc_31q8
    let videoId = url.split("v=")[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  }
}
