import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Doctor } from 'src/app/models/Doctor';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctoresapiService } from '../../../services/doctoresapi.service';
import { DoctorUser } from '../../../models/Doctor';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-formdoctores',
  templateUrl: './formdoctores.component.html',
  styleUrls: ['./formdoctores.component.scss']
})
export class FormdoctoresComponent implements OnInit {
  doctor: DoctorUser = { id: 0, fullname: '', email: '', password: '', telefono: '', ciudad: '', codigo: '' };
  mantenerPass: boolean = true;
  mostrarMantenerPass = true;
  error: boolean;
  constructor(
    private actRoute: ActivatedRoute,
    private api: DoctoresapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
    ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getDoctor(data.id);
        this.mostrarMantenerPass = true;
      } else {
        this.mantenerPass = false;
        this.mostrarMantenerPass = false;
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.Doctor.fechaNacimiento);
      }
    });
  }

  getDoctor(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del doctor..." }));

    this.api.getDoctor(id).subscribe(data => {
      if (data) {
        const newDoc: DoctorUser = {
          id: data.id,
          fullname: data.user.name,
          email: data.user.email,
          telefono: data.telefono,
          password: 'no cambiar',
          ciudad: data.ciudad,
          codigo: data.codigo
        }
        this.mantenerPass = true;
        this.doctor = newDoc;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    // console.log(this.doctor);
    this.storeLoading.dispatch(showLoading({ loadingText: "Guardando datos..." }));

    if (this.doctor.id === 0) {
      // const passwordBytes = CryptoJS.enc.Utf16LE.parse(this.doctor.password);
      // const sha1Hash = CryptoJS.SHA1(passwordBytes);
      // this.doctor.password = sha1Hash.toString();
      this.api.createDoctor(this.doctor).subscribe(listaDoctors => {
        this.storeLoading.dispatch(hideLoading());
        this.router.navigateByUrl('/doctores');
      }, (error) => {
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      console.log(this.doctor);
      this.api.updateDoctor(this.doctor).subscribe(listaDoctors => {
        this.storeLoading.dispatch(hideLoading());
        this.router.navigateByUrl('/doctores');
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }
  cambiarContrasenaChecked(){
    if (this.mantenerPass){
      this.doctor.password = "no cambiar";
    }else{
      this.doctor.password = "";
    }
  }

}
