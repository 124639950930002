import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ortho-menu',
  templateUrl: './ortho-menu.component.html',
  styleUrls: ['./ortho-menu.component.scss']
})
export class OrthoMenuComponent implements OnInit {
  @Input() empresa: any;
  @Input() diferencias: any;
  @Input() acreditacion: any;
  @Input() enviarCaso: any;

  constructor() { }

  ngOnInit() {
  }

  scroll(el: any) {
    // console.log(el);
    el.rootElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
