import { Component, OnInit } from '@angular/core';
import { Contenido } from 'src/app/models/Contenido';
import { ActivatedRoute, Router } from '@angular/router';
import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';

@Component({
  selector: 'app-formcontenidos',
  templateUrl: './formcontenidos.component.html',
  styleUrls: ['./formcontenidos.component.scss']
})
export class FormcontenidosComponent implements OnInit {
  contenido: Contenido = { id: 0, key: '', value: '', tipoContenido: 0};
  error: boolean;
  public Editor = ClassicEditor;
  constructor(
    private actRoute: ActivatedRoute,
    private api: ContenidosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
    ) {
    this.actRoute.params.subscribe(data => {
      // console.log(data);
      if (data.id) {
        // console.log(data.id);
        this.getContenido(data.id);
      } else {
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.Contenido.fechaNacimiento);
      }
    });
  }

  getContenido(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del contenido..." }));

    this.api.getContenido(id).subscribe(data => {
      if (data) {
        const newDoc: Contenido = {
          id: data.id,
          key: data.key,
          value: data.value,
          tipoContenido: 0
        };
        // console.log(newDoc);
        this.contenido = newDoc;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Guardando datos..." }));

    // console.log(this.contenido);
    if (this.contenido.id === 0) {
      this.api.createContenido(this.contenido).subscribe(listaContenidos => {
        this.storeLoading.dispatch(hideLoading());
        this.router.navigateByUrl('/contenidos');
      }, (error) => {
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.api.updateContenido(this.contenido).subscribe(listaContenidos => {
        this.storeLoading.dispatch(hideLoading());
        this.router.navigateByUrl('/contenidos');
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }

}
