import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-diferencias',
  templateUrl: './diferencias.component.html',
  styleUrls: ['./diferencias.component.scss']
})
export class DiferenciasComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;

  constructor() { }

  ngOnInit() {
  }

}
