import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { hideLoading } from "../redux/actions/loading.actions";
import { logout } from "../redux/actions/user.actions";
import { LoadingState } from "../redux/reducers/loading.reducer";
import { UserState } from "../redux/reducers/user.reducer";
import { TokenService } from "../services/token.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private token: TokenService,
        private store: Store<{ user: UserState }>,
        private router: Router,
        private storeLoading: Store<LoadingState>
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => event), // pass further response
            catchError((error: HttpErrorResponse) => {
                // here will be catched error from response, just check if its 401
                if (error && error.status == 401) {
                    // then logout e.g. this.authService.logout()
                    this.token.remove();
                    this.store.dispatch(logout());
                    this.router.navigateByUrl('/login');
                    this.storeLoading.dispatch(hideLoading());
                    return throwError(error);
                }
            }));
    }
}