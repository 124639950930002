import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { ContenidoSingleResponse, ContenidoResponse, Contenido } from '../models/Contenido';
import { map } from 'rxjs/operators';
import { CaptchaResponse, Contacto, ContactoResponse } from '../models/Contacto';

@Injectable({
  providedIn: 'root'
})
export class ContenidosapiService extends BaseService {
  getListaContenidosTipo(tipoContenido: number) {
    return this.getBaseRequest<ContenidoResponse>('api/contenidos/tipo/' + tipoContenido)
      .pipe(map(data => data.data));
  }

  createContenido(contenido: Contenido) {
    return this.postBaseRequest<ContenidoSingleResponse>('api/contenidos', contenido)
      .pipe(map(data => data.data));
  }
  updateContenido(contenido: Contenido) {
    return this.putBaseRequest<ContenidoSingleResponse>('api/contenidos/' + contenido.id, contenido)
      .pipe(map(data => data.data));
  }
  deleteContenido(id) {
    return this.deleteBaseRequest<ContenidoSingleResponse>('api/contenidos/' + id)
      .pipe(map(data => data.res));
  }

  getListacontenidos() {
    return this.getBaseRequest<ContenidoResponse>('api/contenidos')
      .pipe(map(data => data.data));
  }
  getContenido(id: string) {
    return this.getBaseRequest<ContenidoSingleResponse>('api/contenidos/' + id)
      .pipe(map(data => data.data));
  }
  enviarFoto(datos: any, id) {
    return this.postFileRequest<ContenidoSingleResponse>('api/contenidos/' + id + '/foto', datos)
      .pipe(map(data => data.data));

  }
  enviarContacto(datos: Contacto) {
    return this.postBaseRequest<ContactoResponse>("api/contacto", datos);
  }
  getCaptchaImage() {
    return this.getBaseRequest<CaptchaResponse>("api/contacto-0");
  }
}
