import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Caso, EstadoCaso } from 'src/app/models/Caso';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { ArchivoCasoapiService } from '../../../services/archivocasoapi.service';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { showLoading } from 'src/app/redux/actions/loading.actions';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import { hideLoading } from '../../../redux/actions/loading.actions';


@Component({
  selector: 'app-formcaso',
  templateUrl: './formcaso.component.html',
  styleUrls: ['./formcaso.component.scss']
})
export class FormcasoComponent implements OnInit {
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  fechaNacimientoObject: NgbDateStruct;
  error: boolean;
  fotoSuperiorData: any;
  fotoSuperior: any;
  fotoInferiorData: any;
  fotoInferior: any;
  fotoDerechaData: any;
  fotoDerecha: any;
  fotoIzquierdarData: any;
  fotoIzquierda: any;
  frenteIntraoralData: any;
  frenteIntraoral: any;
  frenteFacial: any;
  frenteFacialData: any;
  frenteFacialSonrisa: any;
  frenteFacialSonrisaData: any;
  fotografiaPerfilFacial: any;
  fotografiaPerfilFacialData: any;
  urlFotoSuperior: any;
  urlFotoIzquierda: any;
  urlFrenteIntraoral: any;
  urlFotoDerecha: any;
  urlFotoInferior: any;
  urlFrenteFacial: any;
  urlFrenteFacialSonrisa: any;
  urlPerfilFacial: any;
  constructor(
    private actRoute: ActivatedRoute,
    private api: CasosapiService,
    private router: Router,
    private parserFormatter: NgbDateParserFormatter,
    private apiArchivo: ArchivoCasoapiService,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private storeLoading: Store<LoadingState>

  ) {
    const date: NgbDate = new NgbDate(1900, 1, 1);                // July, 14 1789
    this.ngbDatepickerConfig.minDate = date;
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCaso(data.id);
      }
    });
  }

  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.fechaNacimientoObject = this.parserFormatter.parse(this.caso.fechaNacimiento);
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());

    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    // console.log(this.caso);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando datos..." }));
    this.caso.fechaNacimiento = this.parserFormatter.format(this.fechaNacimientoObject);
    this.caso.status = EstadoCaso.APROBACION_SOLICITADA;
    if (this.caso.id === 0) {
      this.api.createCaso(this.caso).subscribe(caso => {
        this.caso = caso;
        sessionStorage.setItem('caso_id', this.caso.id.toString());
        // this.router.navigateByUrl('/planestratamiento/create');
        this.enviarImagenes();
      }, (error) => {
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.api.updateCaso(this.caso).subscribe(caso => {
        this.caso = caso;
        this.enviarImagenes();
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }
  seleccionaFotoSuperior(e) {
    this.fotoSuperiorData = e.target.files[0];
    // console.log(this.fotoSuperiorData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoSuperior = reader.result;
      }
    }

  }
  seleccionarFotoInferior(e) {
    this.fotoInferiorData = e.target.files[0];
    // console.log(this.fotoInferiorData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoInferior = reader.result;
      }
    }

  }
  seleccionarFotoDerecha(e) {
    this.fotoDerechaData = e.target.files[0];
    // console.log(this.fotoDerechaData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoDerecha = reader.result;
      }
    }

  }
  seleccionarFotoIzquierda(e) {
    this.fotoIzquierdarData = e.target.files[0];
    // console.log(this.fotoIzquierdarData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotoIzquierda = reader.result;
      }
    }

  }
  seleccionaFrenteIntraoral(e) {
    this.frenteIntraoralData = e.target.files[0];
    // console.log(this.frenteIntraoralData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFrenteIntraoral = reader.result;
      }
    }

  }
  seleccionaFrenteFacial(e) {
    this.frenteFacialData = e.target.files[0];
    // console.log(this.frenteFacialData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFrenteFacial = reader.result;
      }
    }

  }
  seleccionaFrenteFacialSonrisa(e) {
    this.frenteFacialSonrisaData = e.target.files[0];
    // console.log(this.frenteFacialSonrisaData);
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFrenteFacialSonrisa = reader.result;
      }
    }

  }
  seleccionaFotografiaPerfilFacial(e) {
    this.fotografiaPerfilFacialData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlPerfilFacial = reader.result;
      }
    }

    // console.log(this.fotografiaPerfilFacialData);
  }

  async enviarImagen(fileData, identifier) {
    if (fileData) {
      const myFormData = new FormData();
      myFormData.append('file', fileData)
      myFormData.append('tipoArchivo', TipoArchivo.FOTOGRAFIA_PACIENTE.toString());
      myFormData.append('step', EstadoCaso.APROBACION_SOLICITADA.toString());
      myFormData.append('identificador', identifier);
      // console.log(fileData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.caso.id).toPromise();
      if (data) {
        // console.log('archivo enviado correctamente');
      }
      // this.redirectToList();

    } else {
      // this.redirectToList();
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
  }
  async enviarImagenes() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto superior..." }));
    await this.enviarImagen(this.fotoSuperiorData, 'foto-superior');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto inferior..." }));
    await this.enviarImagen(this.fotoInferiorData, 'foto-inferior');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto izquierda..." }));
    await this.enviarImagen(this.fotoIzquierdarData, 'foto-izquierda');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Foto derecha..." }));
    await this.enviarImagen(this.fotoDerechaData, 'foto-derecha');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Frente intraoral..." }));
    await this.enviarImagen(this.frenteIntraoralData, 'frente-intraoral');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Frente facial..." }));
    await this.enviarImagen(this.frenteFacialData, 'frente-facial');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Frente Facial Sonrisa..." }));
    await this.enviarImagen(this.frenteFacialSonrisaData, 'frente-facial-sonrisa');
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Perfil Facial..." }));
    await this.enviarImagen(this.fotografiaPerfilFacialData, 'perfil-facial');
    this.storeLoading.dispatch(hideLoading());

    this.redirectToList();
  }
}
