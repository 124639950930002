import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserdataService } from '../../../services/userdata.service';
import { Observable } from 'rxjs';
import { userHasRole } from 'src/app/models/User';
import { Store } from '@ngrx/store';
import { setUserData } from '../../../redux/actions/user.actions';
import { UserState } from '../../../redux/reducers/user.reducer';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  isAdmin = false;
  userData$: Observable<any>;
  constructor(
    private auth: AuthService,
    private router: Router,
    private store: Store<{ user: UserState }>
  ) {

    this.userData$ = store.select(store => store.user.userData);
    this.userData$.subscribe(data => {
      this.getUserData(data);
    });
  }

  ngOnInit() {

  }
  getUserData(data) {
    // console.log('userData', data);
    if (!data) {
      this.auth.getUser().subscribe(data => {
        // console.log('getUser', data);
        this.store.dispatch(setUserData({ userData: data.user }));
        this.checkAdminPermission(data.user);
      }, (error) => {
        this.router.navigateByUrl('/login');
      });
      return;
    }
    this.checkAdminPermission(data);

    // if (this.userData.isUserDataSaved()) {
    //   this.checkAdminPermission();
    //   return this.userdata.getUserData();
    // }

  }
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
  }

}
