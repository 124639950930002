import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ArchivoRefinamiento, ArchivoRefinamientoResponse, ArchivoRefinamientoSingleResponse } from '../models/Archivorefinamiento';
import { BaseService } from './baseapi.service';

@Injectable({
  providedIn: 'root'
})
export class ArchivorefinamientoapiService extends BaseService {
  enviarFoto(datos: any, id) {
    return this.postFileRequest<ArchivoRefinamientoSingleResponse>('api/archivosrefinamiento/' + id + '/upload', datos)
      .pipe(map(data => data.data));
  }

  createArchivoRefinamiento(doctor: ArchivoRefinamiento) {
    return this.postBaseRequest<ArchivoRefinamientoSingleResponse>('api/archivosrefinamiento', doctor)
      .pipe(map(data => data.data));
  }
  updateArchivoRefinamiento(doctor: ArchivoRefinamiento) {
    return this.putBaseRequest<ArchivoRefinamientoSingleResponse>('api/archivosrefinamiento/' + doctor.id, doctor)
      .pipe(map(data => data.data));
  }
  deleteArchivoRefinamiento(id) {
    return this.deleteBaseRequest<ArchivoRefinamientoSingleResponse>('api/archivosrefinamiento/' + id)
      .pipe(map(data => data.res));
  }

  getListaArchivoRefinamientos() {
    return this.getBaseRequest<ArchivoRefinamientoResponse>('api/archivosrefinamiento')
      .pipe(map(data => data.data));
  }
  getArchivoRefinamiento(id: string) {
    return this.getBaseRequest<ArchivoRefinamientoSingleResponse>('api/archivosrefinamiento/' + id)
      .pipe(map(data => data.data));
  }
}
