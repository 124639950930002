import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { Login, LoginResponse } from '../models/User';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  login(data: Login) {
    return this.postBaseRequest<LoginResponse>('api/auth/login', data, false)
      .pipe(map(data => data));
  }
  getUser() {
    return this.getBaseRequest<any>('api/user/me')
    .pipe(map(data => data));
  }
}
