import { PlanTratamiento } from './PlanTratamiento';
import { Caso, EstadoCaso } from './Caso';
export interface BaseArchivo{
    id: number;
    identificador: string;
    tipoArchivo: TipoArchivo;
    extension: string;   
}
export interface ArchivoCaso extends BaseArchivo{
    caso_id: number;
    step: EstadoCaso;
    solicitud_id?: string;
    caso: Caso;
}
export interface ArchivoCasoResponse {
    res: string;
    data: ArchivoCaso[];
}
export interface ArchivoCasoSingleResponse {
    res: string;
    data: ArchivoCaso;
}
export enum TipoArchivo {
    FOTO_COMPROBANTE = 0,
    FOTOGRAFIA_PACIENTE = 1,
    RADIOGRAFIAS = 2,
    ARCHIVO_STL = 3,
    RESPUESTA = 4,
    PDF = 5
}