import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { Login, userHasRole } from '../../models/User';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/Doctor';
import { loggedIn } from 'src/app/redux/actions/user.actions';
import { UserState } from '../../redux/reducers/user.reducer';
import { setUserData } from '../../redux/actions/user.actions';
import { Observable } from 'rxjs';
import { LoadingState } from '../../redux/reducers/loading.reducer';
import { showLoading, hideLoading } from '../../redux/actions/loading.actions';


@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  loginData: Login = {
    email: '',
    password: ''
  };
  isDoctor: boolean;
  userData$: Observable<any>;
  constructor(
    private location: Location,
    private auth: AuthService,
    private token: TokenService,
    private router: Router,
    private store: Store<UserState>,
    private storeLoading: Store<LoadingState>,

  ) { }

  ngOnInit() {
  }
  backClicked() {
    this.location.back();
  }
  submitLogin() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Iniciando Sesión..." }));
    this.auth.login(this.loginData).subscribe(data => {
      // console.log(data);
      this.token.handle(data.access_token);
      this.store.dispatch(loggedIn({ access_token: data.access_token }));
      this.auth.getUser().subscribe(data => {
        this.store.dispatch(setUserData({ userData: data.user }));
        this.checkAdminPermission(data.user);
        this.storeLoading.dispatch(hideLoading());
      });
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
      alert('Error al iniciar sesión, usuario o contraseña incorrectas');
    });
  }
  checkAdminPermission(userData) {
    this.isDoctor = userHasRole('Doctor', userData);
    if (this.isDoctor) {
      this.router.navigateByUrl('/casos');
    } else {
      this.router.navigateByUrl('/doctores');
    }
  }

}
