import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseFormPlan, PlanFormChanges } from '../../../models/PlanTratamiento';
import { PosicionBoca, Boca } from '../../../models/Dientes';
import { environment } from 'src/environments/environment';
import { isFileImage } from '../../../utilities/FileUtilities';
import { Subject } from 'rxjs';
import { ArchivoCaso, BaseArchivo } from '../../../models/Archivocaso';

@Component({
  selector: 'app-planform',
  templateUrl: './planform.component.html',
  styleUrls: ['./planform.component.scss']
})
export class PlanformComponent implements OnInit {
  @Input() isAdmin: boolean;
  @Input() editable: boolean = true;
  @Input() isRefinamiento: boolean = false;
  @Input() archivos: BaseArchivo[] = [];
  @Output() onDataChanged = new EventEmitter<PlanFormChanges>();

  @Input("planForm") set in(thePlan: BaseFormPlan) {
    if (thePlan) {
      
      this.planForm = thePlan;
      if (thePlan.pregunta2dientes) {
        this.boca2 = JSON.parse(thePlan.pregunta2dientes);
      }
      if (this.planForm.pregunta3dientes) {
        this.boca3 = JSON.parse(thePlan.pregunta3dientes);
      }
      if (this.planForm.pregunta8ddientes) {
        this.boca8 = JSON.parse(thePlan.pregunta8ddientes);
      }
    }
    this.pregunta2DientesSelected({ boca: this.boca2 });
    this.pregunta3DientesSelected({ boca: this.boca3 });
    this.pregunta4dDesgasteChecked();
  }

  boca2: Boca = {
    tl: [
      { numero: '1.8', selected: false, posicionboca: PosicionBoca.TL, index: 0 },
      { numero: '1.7', selected: false, posicionboca: PosicionBoca.TL, index: 1 },
      { numero: '1.6', selected: false, posicionboca: PosicionBoca.TL, index: 2 },
      { numero: '1.5', selected: false, posicionboca: PosicionBoca.TL, index: 3 },
      { numero: '1.4', selected: false, posicionboca: PosicionBoca.TL, index: 4 },
      { numero: '1.3', selected: false, posicionboca: PosicionBoca.TL, index: 5 },
      { numero: '1.2', selected: false, posicionboca: PosicionBoca.TL, index: 6 },
      { numero: '1.1', selected: false, posicionboca: PosicionBoca.TL, index: 7 },
    ],
    tr: [
      { numero: '2.1', selected: false, posicionboca: PosicionBoca.TR, index: 0 },
      { numero: '2.2', selected: false, posicionboca: PosicionBoca.TR, index: 1 },
      { numero: '2.3', selected: false, posicionboca: PosicionBoca.TR, index: 2 },
      { numero: '2.4', selected: false, posicionboca: PosicionBoca.TR, index: 3 },
      { numero: '2.5', selected: false, posicionboca: PosicionBoca.TR, index: 4 },
      { numero: '2.6', selected: false, posicionboca: PosicionBoca.TR, index: 5 },
      { numero: '2.7', selected: false, posicionboca: PosicionBoca.TR, index: 6 },
      { numero: '2.8', selected: false, posicionboca: PosicionBoca.TR, index: 7 },
    ],
    bl: [
      { numero: '4.8', selected: false, posicionboca: PosicionBoca.BL, index: 0 },
      { numero: '4.7', selected: false, posicionboca: PosicionBoca.BL, index: 1 },
      { numero: '4.6', selected: false, posicionboca: PosicionBoca.BL, index: 2 },
      { numero: '4.5', selected: false, posicionboca: PosicionBoca.BL, index: 3 },
      { numero: '4.4', selected: false, posicionboca: PosicionBoca.BL, index: 4 },
      { numero: '4.3', selected: false, posicionboca: PosicionBoca.BL, index: 5 },
      { numero: '4.2', selected: false, posicionboca: PosicionBoca.BL, index: 6 },
      { numero: '4.1', selected: false, posicionboca: PosicionBoca.BL, index: 7 },
    ],
    br: [
      { numero: '3.1', selected: false, posicionboca: PosicionBoca.BR, index: 0 },
      { numero: '3.2', selected: false, posicionboca: PosicionBoca.BR, index: 1 },
      { numero: '3.3', selected: false, posicionboca: PosicionBoca.BR, index: 2 },
      { numero: '3.4', selected: false, posicionboca: PosicionBoca.BR, index: 3 },
      { numero: '3.5', selected: false, posicionboca: PosicionBoca.BR, index: 4 },
      { numero: '3.6', selected: false, posicionboca: PosicionBoca.BR, index: 5 },
      { numero: '3.7', selected: false, posicionboca: PosicionBoca.BR, index: 6 },
      { numero: '3.8', selected: false, posicionboca: PosicionBoca.BR, index: 7 },
    ]
  };
  boca3: Boca = {
    tl: [
      { numero: '1.8', selected: false, posicionboca: PosicionBoca.TL, index: 0 },
      { numero: '1.7', selected: false, posicionboca: PosicionBoca.TL, index: 1 },
      { numero: '1.6', selected: false, posicionboca: PosicionBoca.TL, index: 2 },
      { numero: '1.5', selected: false, posicionboca: PosicionBoca.TL, index: 3 },
      { numero: '1.4', selected: false, posicionboca: PosicionBoca.TL, index: 4 },
      { numero: '1.3', selected: false, posicionboca: PosicionBoca.TL, index: 5 },
      { numero: '1.2', selected: false, posicionboca: PosicionBoca.TL, index: 6 },
      { numero: '1.1', selected: false, posicionboca: PosicionBoca.TL, index: 7 },
    ],
    tr: [
      { numero: '2.1', selected: false, posicionboca: PosicionBoca.TR, index: 0 },
      { numero: '2.2', selected: false, posicionboca: PosicionBoca.TR, index: 1 },
      { numero: '2.3', selected: false, posicionboca: PosicionBoca.TR, index: 2 },
      { numero: '2.4', selected: false, posicionboca: PosicionBoca.TR, index: 3 },
      { numero: '2.5', selected: false, posicionboca: PosicionBoca.TR, index: 4 },
      { numero: '2.6', selected: false, posicionboca: PosicionBoca.TR, index: 5 },
      { numero: '2.7', selected: false, posicionboca: PosicionBoca.TR, index: 6 },
      { numero: '2.8', selected: false, posicionboca: PosicionBoca.TR, index: 7 },
    ],
    bl: [
      { numero: '4.8', selected: false, posicionboca: PosicionBoca.BL, index: 0 },
      { numero: '4.7', selected: false, posicionboca: PosicionBoca.BL, index: 1 },
      { numero: '4.6', selected: false, posicionboca: PosicionBoca.BL, index: 2 },
      { numero: '4.5', selected: false, posicionboca: PosicionBoca.BL, index: 3 },
      { numero: '4.4', selected: false, posicionboca: PosicionBoca.BL, index: 4 },
      { numero: '4.3', selected: false, posicionboca: PosicionBoca.BL, index: 5 },
      { numero: '4.2', selected: false, posicionboca: PosicionBoca.BL, index: 6 },
      { numero: '4.1', selected: false, posicionboca: PosicionBoca.BL, index: 7 },
    ],
    br: [
      { numero: '3.1', selected: false, posicionboca: PosicionBoca.BR, index: 0 },
      { numero: '3.2', selected: false, posicionboca: PosicionBoca.BR, index: 1 },
      { numero: '3.3', selected: false, posicionboca: PosicionBoca.BR, index: 2 },
      { numero: '3.4', selected: false, posicionboca: PosicionBoca.BR, index: 3 },
      { numero: '3.5', selected: false, posicionboca: PosicionBoca.BR, index: 4 },
      { numero: '3.6', selected: false, posicionboca: PosicionBoca.BR, index: 5 },
      { numero: '3.7', selected: false, posicionboca: PosicionBoca.BR, index: 6 },
      { numero: '3.8', selected: false, posicionboca: PosicionBoca.BR, index: 7 },
    ]
  };;
  boca8: Boca = {
    tl: [
      { numero: '1.8', selected: false, posicionboca: PosicionBoca.TL, index: 0 },
      { numero: '1.7', selected: false, posicionboca: PosicionBoca.TL, index: 1 },
      { numero: '1.6', selected: false, posicionboca: PosicionBoca.TL, index: 2 },
      { numero: '1.5', selected: false, posicionboca: PosicionBoca.TL, index: 3 },
      { numero: '1.4', selected: false, posicionboca: PosicionBoca.TL, index: 4 },
      { numero: '1.3', selected: false, posicionboca: PosicionBoca.TL, index: 5 },
      { numero: '1.2', selected: false, posicionboca: PosicionBoca.TL, index: 6 },
      { numero: '1.1', selected: false, posicionboca: PosicionBoca.TL, index: 7 },
    ],
    tr: [
      { numero: '2.1', selected: false, posicionboca: PosicionBoca.TR, index: 0 },
      { numero: '2.2', selected: false, posicionboca: PosicionBoca.TR, index: 1 },
      { numero: '2.3', selected: false, posicionboca: PosicionBoca.TR, index: 2 },
      { numero: '2.4', selected: false, posicionboca: PosicionBoca.TR, index: 3 },
      { numero: '2.5', selected: false, posicionboca: PosicionBoca.TR, index: 4 },
      { numero: '2.6', selected: false, posicionboca: PosicionBoca.TR, index: 5 },
      { numero: '2.7', selected: false, posicionboca: PosicionBoca.TR, index: 6 },
      { numero: '2.8', selected: false, posicionboca: PosicionBoca.TR, index: 7 },
    ],
    bl: [
      { numero: '4.8', selected: false, posicionboca: PosicionBoca.BL, index: 0 },
      { numero: '4.7', selected: false, posicionboca: PosicionBoca.BL, index: 1 },
      { numero: '4.6', selected: false, posicionboca: PosicionBoca.BL, index: 2 },
      { numero: '4.5', selected: false, posicionboca: PosicionBoca.BL, index: 3 },
      { numero: '4.4', selected: false, posicionboca: PosicionBoca.BL, index: 4 },
      { numero: '4.3', selected: false, posicionboca: PosicionBoca.BL, index: 5 },
      { numero: '4.2', selected: false, posicionboca: PosicionBoca.BL, index: 6 },
      { numero: '4.1', selected: false, posicionboca: PosicionBoca.BL, index: 7 },
    ],
    br: [
      { numero: '3.1', selected: false, posicionboca: PosicionBoca.BR, index: 0 },
      { numero: '3.2', selected: false, posicionboca: PosicionBoca.BR, index: 1 },
      { numero: '3.3', selected: false, posicionboca: PosicionBoca.BR, index: 2 },
      { numero: '3.4', selected: false, posicionboca: PosicionBoca.BR, index: 3 },
      { numero: '3.5', selected: false, posicionboca: PosicionBoca.BR, index: 4 },
      { numero: '3.6', selected: false, posicionboca: PosicionBoca.BR, index: 5 },
      { numero: '3.7', selected: false, posicionboca: PosicionBoca.BR, index: 6 },
      { numero: '3.8', selected: false, posicionboca: PosicionBoca.BR, index: 7 },
    ]
  };

  stl1: any;
  stl2: any;
  stl3: any;
  stl4: any;
  stl5: any;
  stl1Data: any;
  stl2Data: any;
  stl3Data: any;
  stl4Data: any;
  stl5Data: any;
  urlRadiografiaLateral: any;
  urlRadiografiaPanoramica: any;
  urlStl1: any;
  urlStl2: any;
  urlStl3: any;
  urlStl4: any;
  urlStl5: any;
  radLateralData: any;
  radLateral: any;
  radPanData: any;
  radPan: any;
  planForm: BaseFormPlan = {
    id: 0,
    pregunta1Arcos: "",
    pregunta2RestriccionDental: '',
    pregunta2dientes: "",
    pregunta3Aditamentos: '',
    pregunta3dientes: "",
    pregunta4aMantenerI: '',
    pregunta4aMantenerD: '',
    pregunta4bMejorarRelacionI: '',
    pregunta4bMejorarRelacionD: '',
    pregunta4cCorreccionI: '',
    pregunta4cCorreccionD: '',
    pregunta4dOpcionMovimiento: "",
    pregunta4daDesgaste: '',
    pregunta4dbSimulacion: '',
    pregunta4dcDistalizacion: '',
    pregunta4deCortes: '',
    pregunta4deElasticoS: '',
    pregunta4deElasticoI: '',
    pregunta4deElasticoV: '',
    pregunta4deElasticoL: '',
    pregunta4deBotonS: '',
    pregunta4deBotonI: '',
    pregunta4deBotonV: '',
    pregunta4deBotonL: '',
    pregunta4dObservacion: '',
    pregunta5aOverjetMostrar: '',
    pregunta5bOverjetMejorar: '',
    pregunta6: "",
    pregunta6b: "",
    pregunta6c: "",
    pregunta6caInstruirSolamenteSup: "",
    pregunta6caInstruirSolamenteInf: "",
    pregunta6baExtruirSolamenteInf: "",
    pregunta6baExtruirSolamenteSup: "",
    pregunta6bbExtruireInstruirInf: "",
    pregunta6bbExtruireInstruirSup: "",
    pregunta6cbInstruiryExtruirSup: "",
    pregunta6cbInstruiryExtruirInf: "",
    pregunta6dOtroObs: '',
    pregunta7: "",
    pregunta7bModificarLineaMediaSup: "",
    pregunta7bModificarLineaMediaInf: "",
    pregunta7bbObservacion: '',
    pregunta8: '',
    pregunta8bOtro: '',
    pregunta8baApinamiento: "",
    pregunta8bbVestibularizar: "",
    pregunta8bcDipAnterior: "",
    pregunta8bdDipPosteriorDer: "",
    pregunta8beDipPosteriorIzq: "",
    pregunta8caApinamiento: "",
    pregunta8cbVestibularizar: "",
    pregunta8ccDipAnterior: "",
    pregunta8cdDipPosteriorDer: "",
    pregunta8ceDipPosteriorIzq: "",
    pregunta8dExtraccion: '',
    pregunta8ddientes: "",
    pregunta9ObservacionesComplementarias: '',
    caso_id: 0,
    created_at: '',
    updated_at: ''
  };
  @Input() shouldPrepareInfo: Subject<boolean>;

  constructor() { }

  ngOnInit() {
    if (this.shouldPrepareInfo) {
      this.shouldPrepareInfo.subscribe(v => {
        
        this.prepararInfo();
      });
    }
  }

  pregunta2DientesSelected(e) {
    // console.log('pregunta2', e.boca);
    this.boca2 = e.boca;
    if (this.bocaTieneDientesSeleccionados(e.boca)) {
      this.planForm.pregunta2RestriccionDental = '0';
    } else {
      this.planForm.pregunta2RestriccionDental = '';
    }
  }
  pregunta3DientesSelected(e) {
    // console.log('pregunta3', e.boca);
    this.boca3 = e.boca;
    if (this.bocaTieneDientesSeleccionados(e.boca)) {
      this.planForm.pregunta3Aditamentos = '2';
    } else {
      this.planForm.pregunta3Aditamentos = '';
    }
  }


  extraccionDientesSelected(e) {
    // console.log('extraccion', e.boca);
    this.boca8 = e.boca;
    if (this.bocaTieneDientesSeleccionados(e.boca)) {
      this.planForm.pregunta8dExtraccion = '1';
    } else {
      this.planForm.pregunta8dExtraccion = '0';
    }
  }
  bocaTieneDientesSeleccionados(boca: Boca) {
    for (const diente of boca.tl) {
      if (diente.selected) {
        return true;
      }
    }
    for (const diente of boca.tr) {
      if (diente.selected) {
        return true;
      }
    }
    for (const diente of boca.br) {
      if (diente.selected) {
        return true;
      }
    }
    for (const diente of boca.bl) {
      if (diente.selected) {
        return true;
      }
    }
    return false;
  }
  pregunta4dDesgasteChecked() {
    if (this.planForm.pregunta4daDesgaste == "1" || this.planForm.pregunta4dbSimulacion == "1" || this.planForm.pregunta4dcDistalizacion == "1" || this.planForm.pregunta4deCortes == "1") {
      this.planForm.pregunta4dOpcionMovimiento = "2";
    } else {
      this.planForm.pregunta4dOpcionMovimiento = "";
    }
  }
  pregunta4deCortesChecked() {
    if (this.planForm.pregunta4deElasticoS == "1" || this.planForm.pregunta4deElasticoI == "1" || this.planForm.pregunta4deElasticoV == "1" || this.planForm.pregunta4deElasticoL == "1" ||
      this.planForm.pregunta4deBotonS == "1" || this.planForm.pregunta4deBotonI == "1" || this.planForm.pregunta4deBotonV == "1" || this.planForm.pregunta4deBotonL == "1") {
      this.planForm.pregunta4deCortes = "1";
    } else {
      this.planForm.pregunta4deCortes = "";
    }
    this.pregunta4dDesgasteChecked();
  }
  changePregunta6a(event) {
    //corregir la mordida abierta
    this.apagarMordidaAbierta();
    //corregir la mordida profunda
    this.apagarMordidaProfunda();
    //otro observaciones
    this.apagarObservaciones();
  }
  changePregunta6b(event) {
    // console.log('changePregunta6b');
    //corregir la mordida profunda
    this.apagarMordidaProfunda();
    //otro observaciones
    this.apagarObservaciones();
  }
  changePregunta6ba(event) {
    // console.log('changePregunta6ba');
    // console.log(this.plantratamiento.pregunta6);
    this.planForm.pregunta6bbExtruireInstruirSup = "";
    this.planForm.pregunta6bbExtruireInstruirInf = "";
    this.planForm.pregunta6 = "2";
  }
  changePregunta6bb(event) {
    // console.log(event);
    // console.log('changePregunta6bb');
    this.planForm.pregunta6baExtruirSolamenteSup = "";
    this.planForm.pregunta6baExtruirSolamenteInf = "";
    this.planForm.pregunta6 = "2";
  }
  changePregunta6c(event) {
    //corregir la mordida abierta
    this.apagarMordidaAbierta();

    //otro observaciones
    this.apagarObservaciones();
  }
  changePregunta6d(event) {
    //corregir la mordida abierta
    this.apagarMordidaAbierta();
    //corregir la mordida profunda
    this.apagarMordidaProfunda();
  }
  changePregunta6baOpciones(event) {
    if (event.target.checked) {
      // console.log('changePregunta6baOpciones')
      this.planForm.pregunta6b = "0";

      this.changePregunta6ba(event);

      this.changePregunta6b(event);

    } else {
      if (this.planForm.pregunta6baExtruirSolamenteInf == "" && this.planForm.pregunta6baExtruirSolamenteSup == "") {
        this.planForm.pregunta6b = ""
        this.planForm.pregunta6 = ""
      }
    }
    // this.changePregunta6ba(event);
  }
  changePregunta6bbOpciones(event) {
    if (event.target.checked) {
      // console.log('changePregunta6bbOpciones');
      this.planForm.pregunta6b = "1";
      this.changePregunta6bb(event);
      this.changePregunta6b(event);
      // this.plantratamiento.pregunta6b = "1";

    } else {
      if (this.planForm.pregunta6bbExtruireInstruirInf == "" && this.planForm.pregunta6bbExtruireInstruirSup == "") {
        this.planForm.pregunta6b = ""
        this.planForm.pregunta6 = ""
      }
    }
  }
  changePregunta6ca(event) {
    // console.log(event);
    // console.log(this.plantratamiento.pregunta6);
    this.planForm.pregunta6cbInstruiryExtruirSup = "";
    this.planForm.pregunta6cbInstruiryExtruirInf = "";
    this.planForm.pregunta6 = "3";
  }
  changePregunta6caOpciones(event) {
    if (event.target.checked) {
      this.planForm.pregunta6c = "0";
      this.changePregunta6ca(event);
      this.changePregunta6c(event);
    } else {
      if (this.planForm.pregunta6caInstruirSolamenteInf == "" && this.planForm.pregunta6caInstruirSolamenteSup == "") {
        this.planForm.pregunta6c = ""
        this.planForm.pregunta6 = ""
      }
    }
  }
  changePregunta6cb(event) {
    // console.log(event);
    this.planForm.pregunta6caInstruirSolamenteSup = "";
    this.planForm.pregunta6caInstruirSolamenteInf = "";
    this.planForm.pregunta6 = "3";
  }
  changePregunta6cbOpciones(event) {
    if (event.target.checked) {
      this.planForm.pregunta6c = "1";
      this.changePregunta6cb(event);
      this.changePregunta6c(event);
    } else {
      if (this.planForm.pregunta6cbInstruiryExtruirInf == "" && this.planForm.pregunta6cbInstruiryExtruirSup == "") {
        this.planForm.pregunta6c = ""
        this.planForm.pregunta6 = ""
      }
    }
  }
  apagarMordidaAbierta() {
    // console.log('apagarMordidaAbierta');
    this.planForm.pregunta6b = "";
    this.planForm.pregunta6bbExtruireInstruirSup = "";
    this.planForm.pregunta6bbExtruireInstruirInf = "";
    this.planForm.pregunta6baExtruirSolamenteSup = "";
    this.planForm.pregunta6baExtruirSolamenteInf = "";
  }
  apagarMordidaProfunda() {
    // console.log('apagarMordidaProfunda');
    this.planForm.pregunta6c = "";
    this.planForm.pregunta6caInstruirSolamenteSup = "";
    this.planForm.pregunta6caInstruirSolamenteInf = "";
    this.planForm.pregunta6cbInstruiryExtruirSup = "";
    this.planForm.pregunta6cbInstruiryExtruirInf = "";
  }
  apagarObservaciones() {
    // console.log('apagarObservaciones');
    this.planForm.pregunta6dOtroObs = "";
  }
  changePregunta6Obs(event) {
    // console.log('changePregunta6Obs');

    if (this.planForm.pregunta6dOtroObs == "") {
      this.planForm.pregunta6 = "";
    } else {
      this.apagarMordidaAbierta();
      this.apagarMordidaProfunda();
      this.planForm.pregunta6 = "4"
    }
  }
  changePregunta7bOpciones(event) {
    if (event.target.checked) {
      this.planForm.pregunta7 = "2";
    } else {
      this.planForm.pregunta7 = "";
    }
  }
  changePregunta8Obs(event) {
    if (this.planForm.pregunta8bOtro == "") {
      this.planForm.pregunta8 = "";
    } else {
      this.planForm.pregunta8 = "3";
    }
  }
  prepararInfo() {
    if (this.bocaTieneDientesSeleccionados(this.boca2)) {
      this.planForm.pregunta2dientes = JSON.stringify(this.boca2);
    } else {
      this.planForm.pregunta2dientes = "";
      this.planForm.pregunta2RestriccionDental = null;
    }
    if (this.bocaTieneDientesSeleccionados(this.boca3)) {
      this.planForm.pregunta3dientes = JSON.stringify(this.boca3);
    } else {
      this.planForm.pregunta3dientes = "";
      this.planForm.pregunta3Aditamentos = null;
    }
    if (this.bocaTieneDientesSeleccionados(this.boca8)) {
      this.planForm.pregunta8ddientes = JSON.stringify(this.boca8);
    } else {
      this.planForm.pregunta8ddientes = "";
      this.planForm.pregunta8dExtraccion = null;
    }
    this.onDataChanged.emit({
      planForm: this.planForm,
      radPanData: this.radPanData,
      stl1Data: this.stl1Data,
      stl2Data: this.stl2Data,
      stl3Data: this.stl3Data,
      stl4Data: this.stl4Data,
      stl5Data: this.stl5Data,
      radLateralData: this.radLateralData,
    });
  }

  archivoExiste(identificador: string) {
    return this.archivos.filter(archivo => archivo.identificador == identificador).length > 0;
  }

  getFileUrl(file, extension) {
    if (this.isRefinamiento) {
      return environment.baseUrl + '/files/archivosrefinamiento/' + this.planForm.id + '/' + file + '.' + extension;
    } else {
      return environment.baseUrl + '/files/archivoscaso/' + this.planForm.caso_id + '/' + file + '.' + extension;
    }
  }
  seleccionarRadiografiaLateral(e) {
    this.radLateralData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlRadiografiaLateral = reader.result;
      }
    }
  }
  seleccionarSTL1(e) {
    this.stl1Data = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlStl1 = reader.result;
      }
    }
  }
  seleccionarSTL2(e) {
    this.stl2Data = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlStl2 = reader.result;
      }
    }
  }
  pregunta7aChange(e) {
    this.planForm.pregunta7bModificarLineaMediaSup = "";
    this.planForm.pregunta7bModificarLineaMediaInf = "";
  }
  seleccionarSTL3(e) {
    this.stl3Data = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlStl3 = reader.result;
      }
    }
  }
  seleccionarSTL4(e) {
    this.stl4Data = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlStl4 = reader.result;
      }
    }
  }
  seleccionarSTL5(e) {
    this.stl5Data = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlStl5 = reader.result;
      }
    }
  }
  seleccionarRadiografiaPan(e) {
    this.radPanData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlRadiografiaPanoramica = reader.result;
      }
    }
  }
}
