import { Component, OnInit } from '@angular/core';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { Refinamiento } from '../../../models/Refinamiento';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recibirresultadosref',
  templateUrl: './recibirresultadosref.component.html',
  styleUrls: ['./recibirresultadosref.component.scss']
})
export class RecibirresultadosrefComponent implements OnInit {
  error: boolean;
  refinamiento: Refinamiento;
  modalRechazar: any;
  modalAprobar: any;
  constructor(
    private actRoute: ActivatedRoute,
    private api: RefinamientosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del refinamiento..." }));

    this.api.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getFileUrl(file: string) {
    return environment.baseUrl + '/files/archivosrefinamiento/' + this.refinamiento.id + '/' + file + '.pdf';
  }
  aprobarResultados() {
    this.api.doctorApruebaRefinamientoEntregado(this.refinamiento).subscribe(data => {
      // console.log(data);
      this.modalAprobar.close();
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazarResultados() {
    this.api.doctorRechazaRefinamientoEntregado(this.refinamiento).subscribe(data => {
      // console.log(data);
      this.modalRechazar.close();
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }
  aprobar(content) {
    this.modalAprobar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalAprobar.result.then((result) => { }, (reason) => { });
  }

  getThumbnailUrl(url: string) {
    //https://img.youtube.com/vi/<insert-youtube-video-id-here>/hqdefault.jpg
    // https://www.youtube.com/watch?v=W0_CXc_31q8
    let videoId = url.split("v=")[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  }

}
