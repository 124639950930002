import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { SolicitudInformacionSingleResponse, SolicitudInformacion, SolicitudInformacionResponse } from '../models/SolicitudInformacion';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SolicitudinformacionService extends BaseService {

  createSolicitudInformacion(solicitud: SolicitudInformacion) {
    return this.postBaseRequest<SolicitudInformacionSingleResponse>('api/solicitudes', solicitud)
      .pipe(map(data => data.data));
  }
  updateSolicitudInformacion(solicitud: SolicitudInformacion) {
    return this.putBaseRequest<SolicitudInformacionSingleResponse>('api/solicitudes/' + solicitud.id, solicitud)
      .pipe(map(data => data.data));
  }
  deleteSolicitudInformacion(id) {
    return this.deleteBaseRequest<SolicitudInformacionSingleResponse>('api/solicitudes/' + id)
      .pipe(map(data => data.res));
  }

  getListaSolicitudInformacions() {
    return this.getBaseRequest<SolicitudInformacionResponse>('api/solicitudes')
      .pipe(map(data => data.data));
  }
  getListaSolicitudesInformacionByCaso(idCaso: string) {
    return this.getBaseRequest<SolicitudInformacionResponse>('api/casos/' + idCaso + '/solicitudes')
      .pipe(map(data => data.data));
  }
  getSolicitudInformacion(id: string) {
    return this.getBaseRequest<SolicitudInformacionSingleResponse>('api/solicitudes/' + id)
      .pipe(map(data => data.data));
  }
}
