import { Action, createReducer, on } from '@ngrx/store';
import { showLoading, hideLoading } from '../actions/loading.actions';
export interface LoadingState {
    loading: boolean,
    loadingText: string
};
export const initialState: LoadingState = {
    loading: false,
    loadingText: ''
};
const _loadingReducer = createReducer(initialState,
    on(showLoading, (state: LoadingState, { loadingText }) => {
        return { ...state, loadingText, loading: true };
    }),
    on(hideLoading, (state: LoadingState) => {
        return { ...state, loading: false, loadingText: '' };
    })
);
export function LoadingReducer(state: LoadingState | undefined, action: Action) {
    return _loadingReducer(state, action);
}
