import { Component, OnInit, ViewChild } from '@angular/core';
import { Contacto } from '../../models/Contacto';
import { ContenidosapiService } from '../../services/contenidosapi.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('rootElement', { static: false }) rootElement: HTMLElement;
  contacto: Contacto = { nombre: "", email: "", telefono: "", mensaje: "", captcha: "" };
  captchaUrl: SafeResourceUrl;
  constructor(private api: ContenidosapiService, private actRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {
    this.actRoute.params.subscribe(data => {
      this.getCaptcha();
    });
  }

  ngOnInit() {

  }
  getCaptcha() {
    this.api.getCaptchaImage().subscribe(captchaResponse => {
      this.contacto.captcha = "";
      this.captchaUrl = this._sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + captchaResponse.captcha);
    });
  }
  enviarMensaje() {
    this.api.enviarContacto(this.contacto).subscribe(contactoResponse => {
      if (contactoResponse.res == "error") {
        if (contactoResponse.error === "Captcha incorrecto") {
          alert("Hubo un error al enviar el mensaje, Captcha incorrecto");
        }
        else {
          alert("Hubo un error al enviar el mensaje, por favor intente nuevamente");
        }
        this.getCaptcha();
        return;
      }
      this.getCaptcha();
      alert("Mensaje Enviado Correctamente");
    });
  }

}
