import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ortho-home-image',
  templateUrl: './ortho-home-image.component.html',
  styleUrls: ['./ortho-home-image.component.scss']
})
export class OrthoHomeImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
