import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { RefinamientoResponse, RefinamientoSingleResponse, Refinamiento } from '../models/Refinamiento';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefinamientosapiService extends BaseService {

  getListaRefinamientos() {
    return this.getBaseRequest<RefinamientoResponse>('api/refinamientos')
      .pipe(map(data => data.data));
  }
  getByCaso(idCaso: string) {
    return this.getBaseRequest<RefinamientoResponse>('api/casos/' + idCaso + '/refinamientos')
      .pipe(map(data => data.data));
  }
  getRefinamiento(id: string) {
    return this.getBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + id)
      .pipe(map(data => data.data));
  }
  comprobanteInicialEnviado(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/comprobanteenviadostep2', {})
      .pipe(map(data => data.data));
  }
  resultadosEnviados(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/resultadosenviados', refinamiento)
      .pipe(map(data => data.data));
  }
  createRefinamiento(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos', refinamiento)
      .pipe(map(data => data.data));
  }
  updateRefinamiento(refinamiento: Refinamiento) {
    return this.putBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id, refinamiento)
      .pipe(map(data => data.data));
  }
  solicitarAprobacionStep3(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/solicitaraprobacionstep3', refinamiento)
      .pipe(map(data => data.data));
  }
  entregarRefinamiento(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/entregarrefinamiento', {})
      .pipe(map(data => data.data));
  }
  deleteRefinamiento(id) {
    return this.deleteBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + id)
      .pipe(map(data => data.res));
  }
  aprobarRefinamientoStep2(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/aprobarcasostep2', {})
      .pipe(map(data => data.data));
  }
  rechazarComprobanteStep2(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/rechazarcomprobantestep2', refinamiento)
      .pipe(map(data => data.data));
  }
  doctorRechazaRefinamientoEntregado(refinamiento: Refinamiento){
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/doctorrechazarefinamientoentregado', refinamiento)
      .pipe(map(data => data.data));
  }
  doctorApruebaRefinamientoEntregado(refinamiento: Refinamiento){
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/doctorapruebarefinamiento', refinamiento)
      .pipe(map(data => data.data));
  }
  doctorRechazaRefinamiento(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/doctorRechazaPago', refinamiento)
      .pipe(map(data => data.data));
  }
  comprobanteTratamientoEnviado(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/comprobanteenviadostep4', refinamiento)
      .pipe(map(data => data.data));
  }
  aprobarrefinamientostep4(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/aprobarcasostep4', {})
      .pipe(map(data => data.data));
  }
  rechazarComprobanteStep4(refinamiento: Refinamiento) {
    return this.postBaseRequest<RefinamientoSingleResponse>('api/refinamientos/' + refinamiento.id + '/rechazarcomprobantestep4', refinamiento)
      .pipe(map(data => data.data));
  }




}
