import { Component, OnInit } from '@angular/core';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { EstadoCaso, Caso } from 'src/app/models/Caso';
import { ArchivoCasoapiService } from 'src/app/services/archivocasoapi.service';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { hideLoading, showLoading } from 'src/app/redux/actions/loading.actions';
import { LoadingState } from 'src/app/redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-paso2',
  templateUrl: './paso2.component.html',
  styleUrls: ['./paso2.component.scss']
})
export class Paso2Component implements OnInit {
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  error: boolean;
  comprobante: any;
  comprobanteData: any;
  urlComprobante: any;


  constructor(
    private actRoute: ActivatedRoute,
    private apiArchivo: ArchivoCasoapiService,
    private api: CasosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
  ) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCaso(data.id);
      }
    });
  }

  ngOnInit() {
  }
  seleccionarComprobante(e) {
    this.comprobanteData = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlComprobante = reader.result;
      }
    }
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarDatos() {

    // console.log(this.caso);
    this.caso.status = EstadoCaso.PAGO_INICIAL_ENVIADO;
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando Comprobante..." }));
    this.api.comprobanteInicialEnviado(this.caso).subscribe(caso => {
      this.caso = caso;
      this.storeLoading.dispatch(showLoading({ loadingText: "Subiendo imagen..." }));
      this.enviarImagen();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });

  }
  async enviarImagen() {
    if (this.comprobanteData) {
      const myFormData = new FormData();
      myFormData.append('file', this.comprobanteData)
      myFormData.append('tipoArchivo', TipoArchivo.FOTO_COMPROBANTE.toString());
      myFormData.append('step', EstadoCaso.PAGO_INICIAL_ENVIADO.toString());
      myFormData.append('identificador', 'comprobante-inicial');
      // console.log(this.comprobanteData);
      let data = await this.apiArchivo.enviarFoto(myFormData, this.caso.id).toPromise();

      if (data) {
        console.log('archivo enviado correctamente');
        this.storeLoading.dispatch(hideLoading());
      }
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    } else {
      this.storeLoading.dispatch(hideLoading());
      // this.redirectToList();
    }
    this.redirectToList();
  }
  redirectToList() {
    this.router.navigateByUrl('/casos/' + this.caso.id + '/verpaciente');
  }

}
