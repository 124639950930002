import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { CasosapiService } from 'src/app/services/casosapi.service';
import { EstadoCaso, Caso } from 'src/app/models/Caso';
import { Genero } from '../../../models/Caso';
import { environment } from '../../../../environments/environment';
import { getFormattedDateTimeFromString } from '../../../utilities/DateUtilities';
import { userHasRole } from 'src/app/models/User';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';

@Component({
  selector: 'app-paso1',
  templateUrl: './paso1.component.html',
  styleUrls: ['./paso1.component.scss']
})
export class Paso1Component implements OnInit {
  error: boolean;
  generos = Genero;
  caso: Caso = { id: 0, paciente: '', genero: 0, fechaNacimiento: '', ci: '', user_id: 0, status: EstadoCaso.APROBACION_SOLICITADA };
  modalRechazar: any;
  isAdmin = false;
  userData$: Observable<any>;


  constructor(
    private actRoute: ActivatedRoute,
    private api: CasosapiService,
    private router: Router,
    private modalService: NgbModal,
    private store: Store<{ user: UserState }>,
    private storeLoading: Store<LoadingState>


  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getCaso(data.id);
      }
    });
    this.userData$ = this.store.select(state => state.user.userData);
  }

  ngOnInit() {
    this.userData$
      .subscribe(data => {
        // console.log('userData listacasos', data)
        if (!data) {
          return;
        }
        this.checkAdminPermission(data);
      }, (error) => {
        console.log(error);
      });
  }
  checkAdminPermission(userData) {
    this.isAdmin = userHasRole('Admin', userData);
  }
  getCaso(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getCaso(id).subscribe(data => {
      if (data) {
        this.caso = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getImageUrl(file: string) {
    return environment.baseUrl + '/files/archivoscaso/' + this.caso.id + '/' + file + '.jpg';
  }
  aprobarCaso() {
    this.api.aprobarcasostep1(this.caso).subscribe(data => {
      // console.log(data);
      this.router.navigateByUrl('/casos' + '/' + this.caso.id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }

  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }
  confirmarRechazo() {
    this.api.rechazarcasostep1(this.caso).subscribe(data => {
      this.modalRechazar.close('Save click')
      this.router.navigateByUrl('/casos' + '/' + this.caso.id + '/verpaciente');
    }, error => { console.log(error) });

  }
  getFormattedDate(date: string) {
    return getFormattedDateTimeFromString(date);
  }
}
