import { Component, OnInit } from '@angular/core';

import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import { Contacto } from '../../../models/Contacto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-acreditacion-registro',
  templateUrl: './acreditacion-registro.component.html',
  styleUrls: ['./acreditacion-registro.component.scss']
})
export class AcreditacionRegistroComponent implements OnInit {
  contacto: Contacto = { nombre: "", email: "", telefono: "", mensaje: "", captcha: "" };
  captchaUrl: SafeResourceUrl;

  constructor(private api: ContenidosapiService, private actRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer) { 
      this.actRoute.params.subscribe(data => {
        this.getCaptcha();
      });
  
    }

  ngOnInit() {
  }
  getCaptcha() {
    this.api.getCaptchaImage().subscribe(captchaResponse => {
      this.contacto.captcha = "";
      this.captchaUrl = this._sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + captchaResponse.captcha);
    });
  }
  enviarMensaje() {
    this.contacto.mensaje = "Solicitud de acreditación, " + this.contacto.mensaje;
    this.api.enviarContacto(this.contacto).subscribe(contactoResponse => {
      if (contactoResponse.res == "error") {
        if (contactoResponse.error === "Captcha incorrecto") {
          alert("Hubo un error al enviar el mensaje, Captcha incorrecto");
        }
        else {
          alert("Hubo un error al enviar el mensaje, por favor intente nuevamente");
        }
        this.getCaptcha();
        return;
      }
      this.getCaptcha();
      alert("Mensaje Enviado Correctamente");
    });
  }
}
