import { PlanTratamiento } from './PlanTratamiento';
import { SolicitudInformacion } from './SolicitudInformacion';
import { Refinamiento } from './Refinamiento';
import { ArchivoCaso } from './Archivocaso';
export interface Caso {
    id: number;
    paciente: string;
    genero: Genero;
    fechaNacimiento: string;
    ci: string;
    user_id: number;
    plantratamiento?: PlanTratamiento;
    archivos?: ArchivoCaso[];
    status: EstadoCaso;
    solicitudes?: SolicitudInformacion[];
    motivo_rechazo?: string;
    precio_tratamiento_bs?: number;
    descripcion_tratamiento?: string;
    direccion_entrega?: string;
    latitudEntrega?: string;
    longitudEntrega?: string;
    video_entrega?: string;
    created_at?: string;
    reunionZoom?: string;
    refinamientos?: Refinamiento[]
}
export interface CasoResponse {
    res: string;
    data: Caso[];
}
export interface CasoSingleResponse {
    res: string;
    data: Caso;
}
export enum EstadoCaso {
    APROBACION_SOLICITADA = 0,
    CASO_APROBADO = 1,
    PAGO_INICIAL_ENVIADO = 2,
    PAGO_INICIAL_VERIFICADO = 3,
    TRATAMIENTO_SOLICITADO = 4,
    TRATAMIENTO_APROBADO_PAGO_SOLICITADO = 5,
    PAGO_TRATAMIENTO_REALIZADO = 6,
    PAGO_TRATAMIENTO_COMPROBADO = 7,
    CASO_ENTREGADO = 8,
    CASO_ENTREGADO_Y_APROBADO = 9,
    CASO_RECHAZADO_ENVIE_INFO = -1,
    PAGO_INICIAL_ERRONEO = -3,
    INFORMACION_SOLICITADA = -5,
    PAGO_RECHAZADO_POR_DOCTOR = -6,
    PAGO_TRATAMIENTO_ERRONEO = -7,
    CASO_ENTREGADO_RECHAZADO = -9
}
export enum EstadoCasoText {
    'Aprobación Solicitada' = 0,
    'Caso Aprobado' = 1,
    'Pago Inicial Enviado' = 2,
    'Pago Inicial Verificado' = 3,
    'Tratamiento Solicitado' = 4,
    'Pago de tratamiento Solicitado' = 5,
    'Pago de Tramiento realizado' = 6,
    'Pago de Tratamiento comprobado' = 7,
    'Caso entregado' = 8,
    'Caso entregado a dirección especificada' = 9,
    'Caso rechazado' = -1,
    'El pago no pudo ser verificado' = -3,
    'Información Solicitada' = -5,
    'Pago rechazado por doctor' = -6,
    'El pago del tratamiento no pudo ser verificado' = -7,
    'Doctor rechazó caso entregado' = -9
}
export enum Genero {
    Masculino = 1,
    Femenino = 0
}
