import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Refinamiento, EstadoRefinamiento } from '../../../models/Refinamiento';
import { RefinamientosapiService } from '../../../services/refinamientosapi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verpagorefinamiento',
  templateUrl: './verpagorefinamiento.component.html',
  styleUrls: ['./verpagorefinamiento.component.scss']
})
export class VerpagorefinamientoComponent implements OnInit {

  error: boolean;
  refinamiento: Refinamiento = {
    id: 0, status: EstadoRefinamiento.PAGO_FINAL_REFINAMIENTO_REALIZADO, latitudEntrega: '-17.783202', longitudEntrega: '-63.181687',
    motivoEnvio: '',
    motivoOtroExplique: '',
    nroAlineacionSuperior: '',
    nroAlineacionInferior: '',
    caso_id: 0
  };
  modalRechazar: any;
  marker: any = {
    position: {
      lat: Number(this.refinamiento.latitudEntrega),
      lng: Number(this.refinamiento.longitudEntrega),
    },
    title: 'Ubicación actual ',
    options: { animation: google.maps.Animation.BOUNCE },
  }

    ;
  // Configuración de Google Maps 
  center = {
    lat: Number(this.refinamiento.latitudEntrega),
    lng: Number(this.refinamiento.longitudEntrega),
  };
  zoom = 15;
  display?: google.maps.LatLngLiteral;

  constructor(
    private actRoute: ActivatedRoute,
    private api: RefinamientosapiService,
    private router: Router,
    private modalService: NgbModal,
    private storeLoading: Store<LoadingState>

  ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        this.getRefinamiento(data.id);
      }
    });
  }

  ngOnInit() {
  }
  getRefinamiento(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo datos del paciente..." }));

    this.api.getRefinamiento(id).subscribe(data => {
      if (data) {
        this.refinamiento = data;
        if (this.refinamiento.latitudEntrega) {
          this.changeMarkerLocation(Number(this.refinamiento.latitudEntrega), Number(this.refinamiento.longitudEntrega));
        }
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }
  getImageUrl(file: string) {
    return environment.baseUrl + '/files/archivosrefinamiento/' + this.refinamiento.id + '/' + file + '.jpg';
  }
  aprobarComprobante() {
    this.api.aprobarrefinamientostep4(this.refinamiento).subscribe(data => {
      // console.log(data);
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazarComprobante() {
    this.api.rechazarComprobanteStep4(this.refinamiento).subscribe(data => {
      // console.log(data);
      this.modalRechazar.close();
      this.router.navigateByUrl('/casos/' + this.refinamiento.caso_id + '/verpaciente');
    }, (error) => {
      console.log(error);
    });
  }
  rechazar(content) {
    this.modalRechazar = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });

    this.modalRechazar.result.then((result) => { }, (reason) => { });
  }
  changeMarkerLocation(lat, lng) {
    this.marker = {
      position: {
        lat: lat,
        lng: lng,
      },
      title: 'Ubicación actual ',
      options: { animation: google.maps.Animation.BOUNCE },
    };
    this.center = {
      lat: lat,
      lng: lng,
    };
  }
}
