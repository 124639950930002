export interface Diente {
    numero: string;
    selected: boolean;
    posicionboca: PosicionBoca;
    index: number;
}
export interface Boca {
    tl?: Diente[];
    tr?: Diente[];
    bl?: Diente[];
    br?: Diente[];
}
export enum PosicionBoca {
    TL = 'tl',
    TR = 'tr',
    BL = 'bl',
    BR = 'br'
}