import { Component, OnInit } from '@angular/core';
import { Contenido } from 'src/app/models/Contenido';
import { ActivatedRoute, Router } from '@angular/router';
import { ContenidosapiService } from 'src/app/services/contenidosapi.service';
import { environment } from 'src/environments/environment';
import { isFileImage } from 'src/app/utilities/FileUtilities';
import { showLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import { hideLoading } from 'src/app/redux/actions/loading.actions';

@Component({
  selector: 'app-formfotos',
  templateUrl: './formfotos.component.html',
  styleUrls: ['./formfotos.component.scss']
})
export class FormfotosComponent implements OnInit {

  contenido: Contenido = { id: 0, key: '', value: 'foto', tipoContenido: 1 };
  error: boolean;
  filedata: any;
  myFile: any;
  urlFotos: any;
  constructor(
    private actRoute: ActivatedRoute,
    private api: ContenidosapiService,
    private router: Router,
    private storeLoading: Store<LoadingState>
    ) {
    this.actRoute.params.subscribe(data => {
      // console.log(data);
      if (data.id) {
        // console.log(data.id);
        this.getContenido(data.id);
      } else {
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.Contenido.fechaNacimiento);
      }
    });
  }

  getContenido(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo contenido..." }));

    this.api.getContenido(id).subscribe(data => {
      if (data) {
        const newDoc: Contenido = {
          id: data.id,
          key: data.key,
          value: data.value,
          tipoContenido: 1
        };
        // console.log(newDoc);
        this.contenido = newDoc;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Guardando datos..." }));

    // console.log(this.contenido);
    this.contenido.value = 'foto';
    if (this.contenido.id === 0) {
      this.api.createContenido(this.contenido).subscribe(objContenido => {
        this.contenido = objContenido;
        this.enviarImagen();
      }, (error) => {
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.api.updateContenido(this.contenido).subscribe(objContenido => {
        this.contenido = objContenido;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }
  fileEvent(e) {
    this.filedata = e.target.files[0];
    var reader = new FileReader();
    if (isFileImage(e.target.files[0])) {

      reader.readAsDataURL(e.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.urlFotos = reader.result;
      }
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/fotos');
  }
  enviarImagen() {
    if (this.filedata) {
      const myFormData = new FormData();
      myFormData.append('image', this.filedata);
      // console.log(this.filedata);
      this.api.enviarFoto(myFormData, this.contenido.id).subscribe(data => {
        if (data) {
          // console.log('foto enviada correctamente');
        }
        this.storeLoading.dispatch(hideLoading());
        this.redirectToList();
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.storeLoading.dispatch(hideLoading());
      this.redirectToList();
    }
  }
  getImageUrl(contenido) {
    return environment.baseUrl + '/img/contenidos/' + contenido.id + '.jpg?rand=' + Math.random();
  }
}
