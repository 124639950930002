import { Component, OnInit } from '@angular/core';
import { CasosapiService } from '../../../services/casosapi.service';
import { ActivatedRoute } from '@angular/router';
import { Caso, Genero } from 'src/app/models/Caso';
import { EstadoCaso, EstadoCasoText } from '../../../models/Caso';
import { LanguageApp } from 'src/app/utilities/LanguageApp';
import { getHumanDateTimeFromString } from 'src/app/utilities/DateUtilities';
import { showLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';
import { hideLoading } from 'src/app/redux/actions/loading.actions';
@Component({
  selector: 'app-listacasos',
  templateUrl: './listacasos.component.html',
  styleUrls: ['./listacasos.component.scss']
})
export class ListacasosComponent implements OnInit {
  estadosCaso = EstadoCaso;
  casos: Caso[] = [];
  idDoctor: number;
  dtOptions: any;


  constructor(
    private api: CasosapiService,
    private actRoute: ActivatedRoute,
    private storeLoading: Store<LoadingState>,
  ) {
    // constructor(){

    this.actRoute.params.subscribe(data => {
      if (data) {
        if (data.idDoctor) {
          this.idDoctor = data.idDoctor;
        }
        // console.log(data.id);
        this.recargarCasos();
      }
    });
  }
  ngOnInit(): void {
    this.dtOptions = {
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'colvis',
        'copy',
        'print',
        'excel',
        'csv'
      ],
      language: LanguageApp.spanish_datatables,
      order: []
    };
  }
  recargarCasos() {
    this.storeLoading.dispatch(showLoading({ loadingText: "Cargando lista de pacientes..." }));
    if (this.idDoctor) {
      // console.log(this.idDoctor);
      this.api.getCasosByDoctor(this.idDoctor).subscribe(listaCasos => {
        this.casos = listaCasos;
        this.storeLoading.dispatch(hideLoading());
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    } else {
      this.api.getListacasos().subscribe(listaCasos => {
        this.casos = listaCasos;
        this.storeLoading.dispatch(hideLoading());
      }, (error) => {
        console.log(error);
        this.storeLoading.dispatch(hideLoading());
      });
    }
  }



  getGenero(genero: Genero) {
    return Genero[genero];
  }
  getEstado(caso: Caso) {
    if (this.tieneSolicitudesAbiertas(caso)) {
      return "Información Solicitada";
    }
    return EstadoCasoText[caso.status];
  }
  tieneSolicitudesAbiertas(caso: Caso) {
    if (!caso.solicitudes) {
      return false;
    }
    if (caso.solicitudes.length == 0) {
      return false;
    }
    let solicitudesFiltradas = caso.solicitudes.filter(sol => !sol.resuelto);
    if (solicitudesFiltradas.length == 0) {
      return false;
    }
    return true;
  }

  
  getGoogleMapsLink(caso: Caso) {
    return "http://www.google.com/maps/place/" + caso.latitudEntrega + "," + caso.longitudEntrega;

  }
  getFechaCreacion(fecha) {
    return getHumanDateTimeFromString(fecha);
  }
}
