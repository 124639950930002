import { Component, OnInit } from '@angular/core';
import { SolicitudInformacion } from '../../../models/SolicitudInformacion';
import { SolicitudinformacionService } from '../../../services/solicitudinformacion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoArchivo } from 'src/app/models/Archivocaso';
import { ArchivoCasoapiService } from 'src/app/services/archivocasoapi.service';
import { showLoading, hideLoading } from '../../../redux/actions/loading.actions';
import { LoadingState } from '../../../redux/reducers/loading.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-versolicitud',
  templateUrl: './versolicitud.component.html',
  styleUrls: ['./versolicitud.component.scss']
})
export class VersolicitudComponent implements OnInit {
  solicitud: SolicitudInformacion = { id: 0, texto_solicitud: '', texto_respuesta: '', step: 0, caso_id: 0, incluye_archivos: false, resuelto: false };
  error: boolean;
  selectedFiles: any[] = [];
  constructor(
    private actRoute: ActivatedRoute,
    private api: SolicitudinformacionService,
    private router: Router,
    private apiArchivo: ArchivoCasoapiService,
    private storeLoading: Store<LoadingState>
    ) {
    this.actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getSolicitud(data.id);
      } else {
        // this.fechaNacimientoObject = this.parserFormatter.parse(this.Solicitud.fechaNacimiento);
      }
    });
  }

  getSolicitud(id: string) {
    this.storeLoading.dispatch(showLoading({ loadingText: "Obteniendo solicitud..." }));

    this.api.getSolicitudInformacion(id).subscribe(data => {
      if (data) {
        this.solicitud = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.storeLoading.dispatch(hideLoading());
    });
  }

  ngOnInit() {
  }
  enviarRespuesta() {
    if (this.solicitud.incluye_archivos && this.selectedFiles.length == 0) {
      alert('Se solicitó que se envíe al menos 1 archivo como respuesta');
      return;
    }
    
    this.solicitud.resuelto = true;
    // console.log(this.solicitud);
    this.storeLoading.dispatch(showLoading({ loadingText: "Enviando respuesta..." }));

    this.api.updateSolicitudInformacion(this.solicitud).subscribe(listaSolicitudes => {
      this.enviarArchivos();
    }, (error) => {
      console.log(error);
      this.storeLoading.dispatch(hideLoading());
    });
  }
  async enviarArchivos() {
    this.selectedFiles.forEach(async (file, index) => {
      await this.enviarImagen(file, 'solicitud_' + this.solicitud.id + '_' + index);
    });
    this.storeLoading.dispatch(hideLoading());
    
    this.router.navigateByUrl('casos/'+this.solicitud.caso_id+'/solicitudes');
  }

  async enviarImagen(fileData, identifier) {
    if (fileData) {
      const myFormData = new FormData();
      myFormData.append('file', fileData)
      myFormData.append('tipoArchivo', TipoArchivo.RESPUESTA.toString());
      myFormData.append('step', this.solicitud.step.toString());
      myFormData.append('identificador', identifier);
      myFormData.append('solicitud_id', this.solicitud.id.toString());
      // console.log(fileData);
      await this.apiArchivo.enviarFoto(myFormData, this.solicitud.caso_id).subscribe(data => {
        if (data) {
          // console.log('archivo enviado correctamente');
        }
        // this.redirectToList();
      }, (error) => {
        console.log(error);
      });
    } else {
      // this.redirectToList();
    }
  }
  archivosSeleccionados(e) {
    // console.log(e);
    this.selectedFiles = e.files;
  }
}
